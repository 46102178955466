<div id="page-wrapper">
  <div class="container-fluid">
    
    <div >
      <ol class="breadcrumb">
        <li class="breadcrumb-list"><a routerLink=' '>Inventory</a></li>
      </ol>
    </div>

    <div class="row">
      <div class="col-lg-9 col-sm-8 col-md-8 pull-right">
        <button routerLink='/addItem' target=""
          class="btn btn-primary pull-right m-l-20   waves-effect waves-light m-r-20">
          Add Item</button>
      </div>
    </div>
    <br>
  <br>

    <div class="table-responsive manage-table  m-l-20 m-r-20 ng-table-responsive" style="display:flex; flex-direction: column; ">

      <div class="row">
        <div class=" pull-right">
          <mat-form-field>
              <input
                (keyup)='applyFilter()'
                [(ngModel)]="searchKey"
                type="text"
                matInput
                placeholder="Search"
                autocomplete="off">
          </mat-form-field>
        </div>
      </div>


   

        <table mat-table [dataSource]="dataSource" class="my-table" #myTable style="flex: 1;">
  
          <!-- category Column -->
          <ng-container matColumnDef="category">
              <th mat-header-cell *matHeaderCellDef class="table-header"> Category </th>
              <td mat-cell *matCellDef="let item"> {{ item.categoryName }} </td>
          </ng-container>
  
          <!-- Total Quantity Column -->
          <ng-container matColumnDef="totalQuantity">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Quantity </th>
            <td mat-cell *matCellDef="let item"> {{ item.totalCount }} </td>
          </ng-container>
  
          <!-- Available Quantity Column -->
          <ng-container matColumnDef="availableQuantity">
            <th mat-header-cell *matHeaderCellDef class="table-header"> Available </th>
            <td mat-cell *matCellDef="let item"> {{ item.availableCount }} </td>
          </ng-container>
  
          <!-- Allocated Quantity Column -->
          <ng-container matColumnDef="allocatedQuantity">
            <th mat-header-cell *matHeaderCellDef class="table-header"> UnAvailable </th>
            <td mat-cell *matCellDef="let item" > {{ item.allocatedCount }} </td>
          </ng-container>
  
          <!-- Action Column -->
          <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
              <td mat-cell *matCellDef="let item">
  
                <button class="btn btn-primary ml-5"
                        [routerLink]="['/inventoryDetails', item.id]" style="margin-left:5px">Visit</button>
  
              </td>
          </ng-container>
  
          <!-- Footer -loading & nodata-->
          <ng-container matColumnDef="loading">
              <td mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </td>
          </ng-container>
          <ng-container matColumnDef="noData">
              <td mat-footer-cell *matFooterCellDef colspan="6"> No data. </td>
          </ng-container>
  
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"
              class="footer-row"></tr>
          <tr mat-footer-row *matFooterRowDef="['noData']"
              [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length ==0)}" class="footer-row"></tr>
          </table>
           <div class="paginator-responsive">
            <mat-paginator  #paginator [pageSizeOptions]='[5]' showFirstLastButtons style="flex: 1;"></mat-paginator>
           </div>

      
    </div>
   </div>

  </div>

