<div id="page-wrapper">
  <div class="container-fluid">



      <!-- ============================================================== -->
      <!-- wallet, & manage users widgets -->
      <!-- ============================================================== -->
      <!-- .row -->
      <div>
          <ol class="breadcrumb">
              <li><a routerLink="/employeeList">Employee</a></li>
              <li *ngIf="allocatedPerson">{{allocatedPerson.employeeName}}</li>
          </ol>
      </div>

      <div class="row  m-l-5 m-l-20">
          <div class="white-box col-lg-6">
            <!-- <img src="../plugins/images/users/varun.jpg" alt="varun"
                  class=" img-responsive"> -->


              <ul class="list-icons m-t-20" *ngIf="allocatedPerson">
                  <li>
                      <p><span class="box-title">Employee Name : </span>{{allocatedPerson.employeeName}}</p>
                  </li>
                  <li>
                      <p><span class="box-title">Email : </span>{{allocatedPerson.email}}</p>
                  </li>
                  <li>
                      <p><span class="box-title">Phone number : </span>{{allocatedPerson.phoneNmber}}</p>
                  </li>


              </ul>

          </div>




      </div>


      <h1 class="m-l-20">Allocated Products</h1>
      <div class="table-responsive manage-table m-l-20 m-r-20">
          <table class="table" cellspacing="14">
              <thead>
                  <tr>
                      <th>Product</th>
                      <th>Brand</th>
                      <th>Product Id</th>
                      <th>Action</th>

                  </tr>
              </thead>
              <tbody>
                  <tr class="advance-table-row " *ngFor="let item of allocatedItems">

                      <td>{{item.categoryId.categoryType}}</td> <!--item.categoryId.categoryType-->
                      <td>{{item.brand}}</td>
                      <td>{{item.label}}</td>
                      <td><button class="btn btn-danger"
                        (click)="onRemove(item.label)">Deallocate</button></td>
                  </tr>
              </tbody>
          </table>
      </div>
  </div>
  <!-- /.container-fluid -->
</div>


