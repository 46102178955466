import {Component, OnInit} from '@angular/core';
import {AvailableProductService} from 'src/app/services/available-product.service';
import {SweetalertService} from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-available-product',
  templateUrl: './available-product.component.html',
  styleUrls: ['./available-product.component.css']
})
export class AvailableProductComponent implements OnInit {


  items: any[] = [];
  finalItemData: any[] = [];
  sortedFinalItemData: any[] = [];

  constructor(private availableItemService: AvailableProductService) {
    this.availableItemService = availableItemService;
  }

  ngOnInit() {

    this.availableItemService.refreshNeeded.subscribe(() => {
      this.getAvailableProducts();
    });
    this.getAvailableProducts();
  }


  getAvailableProducts() {
    this.availableItemService.getAvailableProducts().subscribe(
      (res) => {
        if (res.success) {
          // this.items = res.data;
          // console.log(this.items)
          res.data.forEach((item) => {
            const itemData = {
              categoryName: item.category_name[0].categoryType,
              categoryCount: item.categoryCount,
              firstLetter: item.category_name[0].categoryType.charAt(0),
              colorCode: this.getRandomColor(),
              _id: item._id
            };
            this.finalItemData.push(itemData);
          });

          this.sortedFinalItemData = this.finalItemData.sort((a, b) => {
            if(a.firstLetter < b.firstLetter) { return -1; }
            if(a.firstLetter > b.firstLetter) { return 1; }
            return 0;
            });

        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  getRandomColor() {
    const letters = 'ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }
}
