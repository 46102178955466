import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Util } from '../common/utill';
import { Employee } from '../models/employee-model';
import { AppResponse } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient, private router: Router) {}

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  getAllEmployees(): Observable<AppResponse<any>> {
    const url = Util.apiAuthUrl('getEmployee');
    return this.httpClient.get<AppResponse<any>>(url).pipe(
      map(
        (res) => {
          return res;
        }
      )
    );
  }

  addEmployee(employee: Employee): Observable<AppResponse<Employee>> {
    const url = Util.apiAuthUrl('addEmployee');
    return this.httpClient.post<AppResponse<Employee>>(url, employee).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  deleteEmployee(employee: Employee): Observable<AppResponse<Employee>> {
    const url = Util.apiAuthUrl(`deleteEmployee/${employee._id}`);
    return this.httpClient.delete<AppResponse<Employee>>(url).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  updateEmployee(
    employee: Employee,
    updateEmployeeData: Employee
  ): Observable<AppResponse<Employee>> {
    const url = Util.apiAuthUrl(`updateEmployee/${employee._id}`);
    return this.httpClient
      .put<AppResponse<Employee>>(url, updateEmployeeData)
      .pipe(
        map((res) => {
          return res;
        }),
        tap(() => {
          this._refreshNeeded.next();
        })
      );
  }

  getIndividualEmployee(id: string): Observable<AppResponse<any>> {
    const url = Util.apiAuthUrl(`getIndividualEmployee/${id}`);
    return this.httpClient.get<AppResponse<any>>(url).pipe(
      map((res) => {
        return res;
      })
    );
  }

  removeAllocatedItem(id: string): Observable<AppResponse<any>> {
    const url = Util.apiAuthUrl(`removeAllocatedProducts/${id}`);
    return this.httpClient.get<AppResponse<any>>(url).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }
}
