<div id="page-wrapper">
    <div class="container-fluid">



        <!-- ============================================================== -->
        <!-- wallet, & manage users widgets -->
        <!-- ============================================================== -->
        <!-- .row -->
        <div>
            <ol class="breadcrumb breadcrumb-list" >
                <li><a href="Inventory-home.html">Category</a></li>

            </ol>
        </div>

        <div class="row  m-l-5">



            <div class="col-md-12">
                <button href="#" target=""
                    class="btn btn-primary pull-right m-l-20 waves-effect waves-light m-r-20"
                    data-toggle="modal" data-target="#myModal2" (click)="onAddCategoryClick()">
                    Add Category</button>
                <div id="myModal2" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 class="modal-title" id="myModalLabel">Add Category</h4>
                            </div>
                            <div class="modal-body m-r-30">
                                <form class="form-horizontal">
                                    <div class="form-group">
                                        <label for="exampleInputuname" class="col-sm-3 control-label">Category
                                            Name</label>
                                        <div class="col-sm-9">
                                            <div class="input-group">
                                                <input [(ngModel)]="categoryData.categoryType" type="text"
                                                    class="form-control" id="exampleInputuname"
                                                    placeholder="Enter Category Name" name="categoryType">
                                                <div class="input-group-addon"><i class="fas fa-stream"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info waves-effect" data-dismiss="modal"
                                    (click)="addCategory()">Add</button>
                            </div>
                        </div>

                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>
                <!-- category update modal-dialog -->
                <div id="categoryUpdateModal" class="modal fade" tabindex="-1" role="dialog"
                    aria-labelledby="myModalLabel" aria-hidden="true">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
                                <h4 class="modal-title" id="myModalLabel">Edit Category</h4>
                            </div>
                            <div class="modal-body ">
                                <form class="form-horizontal">
                                    <div class="form-group">
                                        <label for="exampleInputuname" class="col-sm-3 control-label">Category
                                            Name</label>
                                        <div class="col-sm-9">
                                            <div class="input-group">
                                                <input [(ngModel)]="categoryData.categoryType" type="text"
                                                    class="form-control" id="exampleInputuname"
                                                    placeholder="Enter New Category Name" name="categoryType">
                                                <div class="input-group-addon"><i class="fas fa-stream"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-info waves-effect" data-dismiss="modal"
                                    (click)="updateCategory()">Save Changes</button>
                            </div>
                        </div>

                        <!-- /.modal-content -->
                    </div>
                    <!-- /.modal-dialog -->
                </div>

            </div>

        </div>
        <br>

        <div class="table-responsive manage-table m-l-20 m-r-20 " style="display:flex; flex-direction: column; ">

            <div class=" pull-right">
                <mat-form-field>
                    <input
                      (keyup)='applyFilter()'
                      [(ngModel)]="searchKey"
                      type="text"
                      matInput
                      placeholder="Search"
                      autocomplete="off">
                </mat-form-field>
            </div>


            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #myTable style="flex: 1;">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- category Column -->
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Category </th>
                    <td mat-cell *matCellDef="let element"> {{element.categoryType}} </td>
                </ng-container>


                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let element" style="display:flex; flex-direction: row; justify-content:between; flex:wrap; margin-left:20px; align-items:center; margin-top:5px;"> 
                        <button class="btn btn-primary" data-toggle="modal"
                            data-target="#categoryUpdateModal"
                            (click)="setUpdateCategoryData(element)">Edit</button><button class="btn btn-danger m-l-10"
                            (click)="deleteCategory(element)">Remove</button> </td>
                </ng-container>

                <!-- Footer -loading & nodata-->
                <ng-container matColumnDef="loading">
                    <td mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </td>
                </ng-container>
                <ng-container matColumnDef="noData">
                    <td mat-footer-cell *matFooterCellDef colspan="6"> No data. </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"
                    class="footer-row"></tr>
                <tr mat-footer-row *matFooterRowDef="['noData']"
                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length ==0)}" class="footer-row"></tr>
            </table>
            <div class="paginator-responsive4">
                <mat-paginator  #paginator [pageSizeOptions]='[5]' showFirstLastButtons style="flex: 1;"></mat-paginator>
               </div>
        </div>
    </div>
</div>
