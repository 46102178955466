import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { InventoryComponent } from './main/inventory/inventory.component';
import { InventoryDetailsComponent } from './main/inventory/inventory-details/inventory-details.component';
import { AddItemComponent } from './main/add-item/add-item.component';
import { ItemDescriptionComponent } from './main/item-description/item-description.component';
import { AppRoutingModule } from './app-routing-module';
import { AvailableProductComponent } from './main/available-product/available-product.component';
import { AvailableProductDiscriptionComponent } from './main/available-product/available-product-discription/available-product-discription.component';
import { ViewEmployeeComponent } from './main/employee/view-employee/view-employee.component';
import { EmployeeListComponent } from './main/employee/employee-list/employee-list.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './main/login/login.component';
import { RegisterComponent } from './main/register/register.component';
import { MainLayoutComponent } from './main/main-layout/main-layout.component';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { CategoryComponent } from './main/category/category.component';
import { CustomInterceptor } from './common/custom-intercepter';
import { ProductAllocationComponent } from './main/product-allocation/product-allocation.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { EditItemComponent } from './main/edit-item/edit-item.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { InventoryService } from './services/inventory.service';

@NgModule({
  declarations: [
    AppComponent,
    InventoryComponent,
    InventoryDetailsComponent,
    AddItemComponent,
    ItemDescriptionComponent,
    AvailableProductComponent,
    AvailableProductDiscriptionComponent,
    ViewEmployeeComponent,
    EmployeeListComponent,
    LoginComponent,
    RegisterComponent,
    MainLayoutComponent,
    SearchFilterPipe,
    CategoryComponent,
    ProductAllocationComponent,
    EditItemComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true },
    InventoryService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
