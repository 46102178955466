import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {User} from 'src/app/models/user-model';
import {AuthService} from 'src/app/services/auth.service';
import {SweetalertService} from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginUserData: User = {
    email: null,
    password: null,
  };

  loginForm: FormGroup;

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      password: new FormControl(null, Validators.required),
    });
  }

  loginUser() {
    this.authService.loginUser(this.loginUserData).subscribe(
      (res) => {
        if (res.success) {
          this.router.navigate(['/']);
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }
}
