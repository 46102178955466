import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {Subject} from 'rxjs/internal/Subject';
// import { type } from 'os';
import {InventoryService} from 'src/app/services/inventory.service';
import {SweetalertService} from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {

  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = [
    'category',
    'totalQuantity',
    'availableQuantity',
    'allocatedQuantity',
    'action'];

  data: any;
  finalData: any[] = [];
  sortedFinalData: any[] = [];
  finalNData: any[] = [];

  availableInventoryList: any[] = [];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private inventoryService: InventoryService) {
    this.inventoryService = inventoryService;
  }

  ngOnInit() {

    this.inventoryService.refreshNeeded.subscribe(() => {
      this.getInventory();
    });
    this.getInventory();
  }

  getInventory() {
    this.inventoryService.getInventory().subscribe(
      (res) => {
        if (res.success) {

          if (res.data.availableInventory.length > 0) {

            res.data.availableInventory.forEach(availableItem => {
              this.data = {
                id: availableItem._id,
                categoryName: availableItem.category_name[0].categoryType,
                availableCount: availableItem.categoryCount,
                allocatedCount: 0,
                totalCount: availableItem.categoryCount
              };
              this.finalData.push(this.data);
            });

            res.data.notAvailableInventory.forEach(notAvailableItem => {
              this.finalData.find(avaleItem => {
                if (avaleItem.id === notAvailableItem._id) {
                  avaleItem.allocatedCount = notAvailableItem.categoryCount;
                  avaleItem.totalCount += notAvailableItem.categoryCount;
                } else {
                  if (!this.finalData.some((notAvlItem) => notAvlItem.id === notAvailableItem._id)) { // Removing duplicate
                    this.data = {
                      id: notAvailableItem._id,
                      categoryName: notAvailableItem.category_name[0].categoryType,
                      availableCount: 0,
                      allocatedCount: notAvailableItem.categoryCount,
                      totalCount: notAvailableItem.categoryCount
                    };
                    this.finalData.push(this.data);
                  }
                }
              });
            });
          }

          if (this.finalData.length === 0) {
            res.data.notAvailableInventory.forEach(notAvleItem => {
              this.data = {
                id: notAvleItem._id,
                categoryName: notAvleItem.category_name[0].categoryType,
                availableCount: 0,
                allocatedCount: notAvleItem.categoryCount,
                totalCount: notAvleItem.categoryCount
              };
              this.finalData.push(this.data);
            });
          }


          // Original Code

          // res.data.availableInventory.forEach(availableItem => {
          //   this.data = {
          //     id: availableItem._id,
          //     categoryName: availableItem.category_name[0].categoryType,
          //     availableCount: availableItem.categoryCount,
          //     allocatedCount: 0,
          //     totalCount: availableItem.categoryCount
          //   };
          //   this.finalData.push(this.data);
          // });

          // res.data.notAvailableInventory.forEach(notAvailableItem => {
          //   this.finalData.find(avaleItem => {
          //     if (avaleItem.id === notAvailableItem._id) {
          //       avaleItem.allocatedCount = notAvailableItem.categoryCount;
          //       avaleItem.totalCount += notAvailableItem.categoryCount;
          //     } else {
          //       if (!this.finalData.some((notAvlItem) => notAvlItem.id === notAvailableItem._id)) { // Removing duplicate
          //         this.data = {
          //           id: notAvailableItem._id,
          //           categoryName: notAvailableItem.category_name[0].categoryType,
          //           availableCount: 0,
          //           allocatedCount: notAvailableItem.categoryCount,
          //           totalCount: notAvailableItem.categoryCount
          //         };
          //         this.finalData.push(this.data);
          //       }
          //     }
          //   });
          // });

          this.sortedFinalData = this.finalData.sort((a, b) => {
              if(a.categoryName < b.categoryName) { return -1; }
              if(a.categoryName > b.categoryName) { return 1; }
              return 0;
          });
          this.dataSource = new MatTableDataSource(this.sortedFinalData);
          this.dataSource.paginator = this.paginator;
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
