<section id="wrapper" class="new-login-register">
    <div class="lg-info-panel">
        <div class="inner-panel">
            <a href="javascript:void(0)" class="p-20 di"><img src="https://efitosolutions.com/assets/img/logo.png"></a>
            <div class="lg-content">
                <h2></h2>
                <p class="text-muted"></p>
            </div>
        </div>
    </div>
    <div class="new-login-box">
        <div class="white-box">
            <h3 class="box-title m-b-0">Sign UP to Admin</h3> <small>Enter your details below</small>
            <form class="form-horizontal new-lg-form" id="loginform" action="index.html" (ngSubmit)="onSubmit()"
                [formGroup]='exform'>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <label>Username</label>
                        <input class="form-control" formControlName="username" [(ngModel)]="username" type="text"
                            name="username" placeholder="username">

                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <label>Email</label>
                        <input class="form-control" formControlName="email" [(ngModel)]="email" type="email"
                            required="true" name="email" placeholder="Email">

                    </div>
                </div>
                <div class="form-group ">
                    <div class="col-xs-12">
                        <label>Password</label>
                        <input class="form-control" formControlName="password" [(ngModel)]="password" type="password"
                            required="true" name="password" placeholder="Password">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <label>Confirm password</label>
                        <input class="form-control" formControlName="confirmPassword" type="password" required="true"
                            placeholder="Confirm Password" name="confirmPassword">
                    </div>
                </div>

                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button [disabled]="exform.invalid"
                            class="btn btn-info btn-lg btn-block text-uppercase waves-effect waves-light"
                            type="submit">Sign Up</button>
                    </div>
                </div>
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>Already have an account? <a routerLink='/login' class="text-danger m-l-5"><b>Sign In</b></a>
                        </p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>