import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs/internal/Subject';
import { Employee } from 'src/app/models/employee-model';
import { EmployeeService } from 'src/app/services/employee.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css']
})
export class EmployeeListComponent implements OnInit {

  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = ['employee', 'action'];

  employees: any[] = [];

  employeeData: Employee = {
    employeeName: null,
    email: null,
    phoneNumber: null
  };

  updateEmployeeData: any;
  addEmployeeForm: FormGroup;
  updateEmployeeForm: FormGroup;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private employeeService: EmployeeService) {
    this.employeeService = employeeService;
  }

  ngOnInit(): void {

    this.employeeService.refreshNeeded.subscribe(() => {
      this.getAllEmployees();
    });
    this.getAllEmployees();

    this.addEmployeeForm = new FormGroup({
      employeeName: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      email: new FormControl(null, [Validators.required, Validators.email, Validators.maxLength(50)]),
      phoneNumber: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
    });

    this.updateEmployeeForm = new FormGroup({
      employeeName: new FormControl(null, [Validators.required, Validators.maxLength(50)]),
      email: new FormControl(null, [Validators.email, Validators.required, Validators.maxLength(50)]),
      phoneNumber: new FormControl(null, [Validators.required, Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")])
    });

  }

  getAllEmployees() {
    this.employeeService.getAllEmployees().subscribe(
      (res) => {
        if (res.success) {
          this.employees = res.data.reverse();
          // this.employees = res.data;
          this.dataSource = new MatTableDataSource(this.employees);
          this.dataSource.paginator = this.paginator;
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  onAddEmployee() {
    this.employeeService.addEmployee(this.employeeData).subscribe(
      (res) => {
        if (res.success) {
          SweetalertService.success(res.message);
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  onDeleteEmployee(employee: Employee) {
  
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.deleteFunction(employee);
        this.getAllEmployees();
      }
    })
  }

  deleteFunction(employee: Employee) {
    this.employeeService.deleteEmployee(employee).subscribe(
      (res) => {
        if (res.success) {
          SweetalertService.success(res.message);
          this.getAllEmployees();
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  onUpdateEmployee() {

    this.employeeService
      .updateEmployee(this.updateEmployeeData, this.employeeData)
      .subscribe(
        (res) => {
          if (res.success) {
            
            this.employeeData = this.updateEmployeeData;
            SweetalertService.success(res.message);
           
          } else {
            SweetalertService.error(res.error);
          }
        }
      );
    // this.updateEmployeeData = null;

  }

  setUpdateEmployeeData(employee: Employee) {
    this.updateEmployeeData = {
      _id: employee._id,
      employeeName: employee.employeeName,
      email: employee.email,
      phoneNumber: employee.phoneNumber
    }
    this.employeeData = {
      employeeName: this.updateEmployeeData.employeeName,
      email: this.updateEmployeeData.email,
      phoneNumber: this.updateEmployeeData.phoneNumber
    }
  }

  onRest() {
    this.addEmployeeForm.reset();
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
