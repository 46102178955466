import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Util } from '../common/utill';
import { Category } from '../models/category-model';
import { AppResponse } from '../models/response';

@Injectable({
  providedIn: 'root',
})
export class ProductAllocationService {
  constructor(private http: HttpClient) {}
  private _refreshNeeded$ = new Subject<void>();

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  // getAllAllocatedProducts(): Observable<AppResponse<Category[]>> {
  //   const url = Util.apiAuthUrl('productAllocation/view');
  //   return this.http.get<AppResponse<Category[]>>(url).pipe(
  //     map(
  //       (res) => {
  //         return res;
  //       },
  //       (err) => {
  //         return err;
  //       }
  //     ),
  //     tap(() => {
  //       this._refreshNeeded$.next();
  //     })
  //   );
  // }

  allocateProduct(data) {
    const url = Util.apiAuthUrl('productAllocation/add');
    return this.http.post<AppResponse<Category[]>>(url, data).pipe(
      map(
        (res) => {
          return res;
        },
        (err) => {
          return err;
        }
      ),
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

  deallocateProduct(label) {
    const url = Util.apiAuthUrl(`deleteAllocatedProduct/${label}`);
    return this.http.put<AppResponse<Category[]>>(url, {}).pipe(
      map(
        (res) => {
          return res;
        },
        (err) => {
          return err;
        }
      ),
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }

  getAllocatedInventory(): Observable<AppResponse<any>> {
    const ep = Util.apiAuthUrl('allocatedInventory');
    return this.http.get<AppResponse<any>>(ep).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded$.next();
      })
    );
  }
}
