<div id="page-wrapper">
  <div class="container-fluid">



      <!-- ============================================================== -->
      <!-- wallet, & manage users widgets -->
      <!-- ============================================================== -->
      <!-- .row -->
      <div>
          <ol class="breadcrumb breadcrumb-list">
              <li><a routerLink=''>Inventory</a></li>
              <li><a [routerLink]="['/inventoryDetails', categoryId]">{{categoryName}}</a></li>
              <li><a>{{ item.brand }}</a></li>
              <li><a>{{item.id}}</a></li>
          </ol>
      </div>


      <br>
      <div class="white-box col-lg-6">
        <!-- <img src="../plugins/images/users/varun.jpg" alt="varun"
              class=" img-responsive"> -->


          <ul class="list-icons m-t-20">
              <li>
                  <p><span class="box-title">Brand : </span>{{ item.brand }}</p>
              </li>
              <li>
                  <p><span class="box-title">Id : </span>{{item.id}}</p>
              </li>
              <li>
                  <!-- <p><span class="box-title">Description : </span></p>
                      <div class="des-item">{{ item.description }}</div> -->

                  <div class="des-item"><span class="box-title">Description : </span><p style="padding-left: 120px;">{{ item.description }}</p></div>
              </li>
              <li>
                  <p><span class="box-title">Price : </span>Rs.&ensp;{{ item.price }}</p>
              </li>
              <li>
                  <p><span class="box-title">Purchase Date : </span>{{ item.purchaseDate | date }}</p>
              </li>
              <li>
                  <p><span class="box-title">Uploads : </span></p>

                  <div class="row inline-photos">
                      <div class="col-xs-4" *ngFor="let upload of uploads">
                          <img
                          *ngIf="upload.type!=='application/pdf'"
                          class="img-responsive btn"
                          (click)="onImage(upload.path)"
                          alt={{upload.originalName}}
                          src={{upload.path}}>


                          <img
                          class="img-responsive btn"
                          alt={{upload.originalName}}
                          (click)="onImage(upload.path)"
                          src="https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/PDF_password_0.png?LaA5mWQu0OWJXEMVp9dyAuDR8UH0mYiY&size=770:433"
                          *ngIf="upload.type=='application/pdf'">
                      </div>
                  </div>
              </li>

          </ul>

      </div>

  </div>
  <!-- /.container-fluid -->
</div>
