<section id="wrapper" class="new-login-register">
    <div class="lg-info-panel">
        <div class="inner-panel">
            <a class="p-20 di"><img src="https://efitosolutions.com/assets/img/logo.png"></a>
            <div class="lg-content">
                <h2></h2>
                <p class="text-muted"></p>

            </div>
        </div>
    </div>
    <div class="new-login-box">
        <div class="white-box">
            <h3 class="box-title m-b-0">Sign In to Admin</h3>
            <small>Enter your details below</small>
            <form [formGroup]="loginForm" class="form-horizontal new-lg-form" id="loginform" action="index.html">

                <div class="form-group  m-t-20">
                    <div class="col-xs-12">
                        <label>Email Address</label>
                        <input formControlName="email" class="form-control" type="text" required="" placeholder="Email"
                            [(ngModel)]="loginUserData.email" name="email">
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <label>Password</label>
                        <input formControlName="password" class="form-control" type="password" required=""
                            placeholder="Password" [(ngModel)]="loginUserData.password" name="password">
                    </div>
                </div>

                <div class="form-group text-center m-t-20">
                    <div class="col-xs-12">
                        <button [disabled]="loginForm.invalid"
                            class="btn btn-info btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light"
                            type="submit" (click)="loginUser()">Log In</button>
                    </div>
                </div>

                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        <p>Don't have an account? <a routerLink='/register' class="text-primary m-l-5"><b>Sign
                                    Up</b></a>
                        </p>
                    </div>
                </div>
            </form>

        </div>
    </div>


</section>