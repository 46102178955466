import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Item } from '../models/item-model';
import { Observable, Subject } from 'rxjs';
import { AppResponse } from '../models/response';
import { Util } from '../common/utill';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InventoryService {
  private _refreshNeeded = new Subject<void>();

  constructor(private httpClient: HttpClient, private router: Router) {}

  private _refreshNeeded$ = new Subject<void>();

  get refreshNeeded$() {
    return this._refreshNeeded$;
  }

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  getInventory(): Observable<AppResponse<any>> {
    const ep = Util.apiAuthUrl('inventory');
    return this.httpClient.get<AppResponse<any>>(ep).pipe(
      map((res) => {
        return res;
      })
    );
  }
  getAvailableInventory(): Observable<AppResponse<any>> {
    const ep = Util.apiAuthUrl('avaialbleInventory');
    return this.httpClient.get<AppResponse<any>>(ep).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }
  getAllocatedInventory(): Observable<AppResponse<any>> {
    const ep = Util.apiAuthUrl('allocatedInventory');
    return this.httpClient.get<AppResponse<any>>(ep).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  getIndividualInventory(id: any): Observable<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`inventory/visit/${id}`);
    return this.httpClient.get<AppResponse<any>>(ep).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getIndividualItemDiscription(id: any): Observable<AppResponse<any>> {
    const ep = Util.apiAuthUrl(`inventory/visit/description/${id}`);
    return this.httpClient.get<AppResponse<any>>(ep).pipe(
      map((res) => {
        return res;
      })
    );
  }

  addItem(item: Item): Observable<AppResponse<string>> {
    const url = Util.apiAuthUrl('inventory/visit/addItem');
    return this.httpClient.post<AppResponse<string>>(url, item).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  uploadFile(fileToUpload, itemId): Observable<AppResponse<string>> {
    // console.log(itemId);
    // console.log(fileToUpload)
    const url = Util.apiAuthUrl('upload');

    const formData: FormData = new FormData();

    for (const file of fileToUpload) {
      formData.append('uploads', file);
    }
    formData.append('type', 'type');
    formData.append('title', 'title');
    formData.append('itemId', itemId._id);
    return this.httpClient.post<AppResponse<string>>(url, formData).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  deleteItem(item: Item): Observable<AppResponse<Item>> {
    const url = Util.apiAuthUrl(`inventory/visit/deleteItem/${item._id}`);

    return this.httpClient.delete<AppResponse<Item>>(url).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  deleteUploadItem(id: string): Observable<AppResponse<string>> {
    const url = Util.apiAuthUrl(`deleteUploads/${id}`);
    return this.httpClient.delete<AppResponse<string>>(url).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  updateItem(itemData: Item, id: any): Observable<AppResponse<any>> {
    // console.log(itemData)
    // console.log(id)
    const url = Util.apiAuthUrl(`inventory/visit/editItem/${id}`);
    return this.httpClient.put<AppResponse<any>>(url, itemData).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }
}
