<div id="page-wrapper">
  <div class="container-fluid">



      <!-- ============================================================== -->
      <!-- wallet, & manage users widgets -->
      <!-- ============================================================== -->
      <!-- .row -->
      <div>
          <ol class="breadcrumb breadcrumb-list" >
              <li><a routerLink="/employeeList">Employee</a></li>

          </ol>
      </div>

      <div class="row  m-l-5">



          <div class="col-md-12">
              <button href="#" target=""
                  class="btn btn-primary pull-right m-l-20 waves-effect waves-light m-r-20"
                  data-toggle="modal" data-target="#myModal" (click)="onRest()">
                  Add Employee</button>
              <div id="myModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                      <div class="modal-content">
                          <div class="modal-header">
                              <button type="button" class="close" data-dismiss="modal"
                                  aria-hidden="true">×</button>
                              <h4 class="modal-title" id="myModalLabel">Add Employee</h4>
                          </div>
                          <div class="modal-body m-r-30">

                              <form class="form-horizontal" [formGroup]="addEmployeeForm" #addForm="ngForm">
                                  <div class="form-group">
                                      <label for="exampleInputuname" class="col-sm-3 control-label">Employee
                                          Name *</label>
                                      <div class="col-sm-9">
                                          <div class="input-group">
                                              <input formControlName="employeeName" [(ngModel)]="employeeData.employeeName" type="text" class="form-control"
                                                  placeholder="Enter Employee Name" name="employeeName">
                                              <div class="input-group-addon"><i class="fas fa-stream"></i>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngIf="addEmployeeForm.get('employeeName').invalid && addEmployeeForm.get('employeeName').errors &&
                                                    (addEmployeeForm.get('employeeName').dirty || addEmployeeForm.get('employeeName').touched)"
                                        class="help-block error">

                                        <small
                                          class="text-danger"
                                          *ngIf="addEmployeeForm.get('employeeName').hasError('required')"
                                          >Employee Name Is Required!
                                        </small>
                                        <small
                                          class="text-danger"
                                          *ngIf="addEmployeeForm.get('employeeName').hasError('maxlength')">

                                          The maximum length for this field is {{addEmployeeForm.get('employeeName').errors.maxlength.requiredLength}} characters.
                                        </small>
                                      </div>
                                  </div>

                                  <div class="form-group">
                                      <label for="exampleInputuname"
                                          class="col-sm-3 control-label">Email *</label>
                                      <div class="col-sm-9">
                                          <div class="input-group">
                                              <input formControlName="email" [(ngModel)]="employeeData.email" type="email" class="form-control"
                                                  placeholder="Enter Email" name="email">
                                              <div class="input-group-addon"><i class="fas fa-stream"></i>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngIf="addEmployeeForm.get('email').invalid && addEmployeeForm.get('email').errors &&
                                                    (addEmployeeForm.get('email').dirty || addEmployeeForm.get('email').touched)"
                                        class="help-block error">

                                        <small
                                          class="text-danger"
                                          *ngIf="addEmployeeForm.get('email').hasError('required')"
                                          >Employee Email Is Required!
                                        </small>
                                        <small
                                          class="text-danger"
                                          *ngIf="addEmployeeForm.get('email').hasError('maxlength')">
                                          The maximum length for this field is {{addEmployeeForm.get('email').errors.maxlength.requiredLength}} characters.
                                        </small>
                                      </div>
                                  </div>

                                  <div class="form-group">
                                      <label for="exampleInputuname" class="col-sm-3 control-label">Phone
                                          number *</label>
                                      <div class="col-sm-9">
                                          <div class="input-group">
                                              <input formControlName="phoneNumber" [(ngModel)]="employeeData.phoneNumber" type="text" class="form-control"
                                                  placeholder="Enter Phone number" name="phoneNumber">
                                              <div class="input-group-addon"><i class="fas fa-stream"></i>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngIf="addEmployeeForm.get('phoneNumber').invalid && addEmployeeForm.get('phoneNumber').errors &&
                                                    (addEmployeeForm.get('phoneNumber').dirty || addEmployeeForm.get('phoneNumber').touched)"
                                        class="help-block error">

                                        <small
                                          class="text-danger"
                                          *ngIf="addEmployeeForm.get('phoneNumber').hasError('required')"
                                          >Employee Phone Number Is Required!
                                        </small>
                                        <small
                                          class="text-danger"
                                          *ngIf="addEmployeeForm.get('phoneNumber').hasError('maxlength')">
                                          The maximum length for this field is {{addEmployeeForm.get('phoneNumber').errors.maxlength.requiredLength}} characters.
                                        </small>
                                      </div>

                                  </div>
                              </form>
                          </div>
                          <div class="modal-footer">
                              <button type="submit" class="btn btn-info waves-effect"
                                  data-dismiss="modal" [disabled]="!addForm.valid" (click)="onAddEmployee()">Add</button>
                          </div>
                      </div>
                      <!-- /.modal-content -->
                  </div>
                  <!-- /.modal-dialog -->
              </div>

              <!--/.update-dialog-->
              <div id="employeeUpdateModel" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
                  aria-hidden="true">
                  <div class="modal-dialog">
                      <div class="modal-content">
                          <div class="modal-header">
                              <button type="button" class="close" data-dismiss="modal"
                                  aria-hidden="true">×</button>
                              <h4 class="modal-title" id="myModalLabel">Update Employee</h4>
                          </div>
                          <div class="modal-body m-r-30">
                              <form class="form-horizontal" [formGroup]="updateEmployeeForm" #updateForm="ngForm">

                                  <div class="form-group">
                                      <label for="exampleInputuname" class="col-sm-3 control-label">Employee
                                          Name</label>
                                      <div class="col-sm-9">
                                          <div class="input-group">
                                              <input formControlName="employeeName" [(ngModel)]="employeeData.employeeName" type="text" class="form-control"
                                                  placeholder="Enter Employee Name" name="employeeName">
                                              <div class="input-group-addon"><i class="fas fa-stream"></i>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngIf="updateEmployeeForm.get('employeeName').invalid && updateEmployeeForm.get('employeeName').errors &&
                                                    (updateEmployeeForm.get('employeeName').dirty || updateEmployeeForm.get('employeeName').touched)"
                                        class="help-block error">

                                        <small
                                          class="text-danger"
                                          *ngIf="updateEmployeeForm.get('employeeName').hasError('required')"
                                          >Employee Name Is Required!
                                        </small>
                                        <small
                                          class="text-danger"
                                          *ngIf="updateEmployeeForm.get('employeeName').hasError('maxlength')">

                                          The maximum length for this field is {{updateEmployeeForm.get('employeeName').errors.maxlength.requiredLength}} characters.
                                        </small>
                                      </div>

                                  </div>

                                  <div class="form-group">
                                      <label for="exampleInputuname"
                                          class="col-sm-3 control-label">Email</label>
                                      <div class="col-sm-9">
                                          <div class="input-group">
                                              <input formControlName="email" [(ngModel)]="employeeData.email" type="email" class="form-control"
                                                  placeholder="Enter Email" name="email">
                                              <div class="input-group-addon"><i class="fas fa-stream"></i>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngIf="updateEmployeeForm.get('email').invalid && updateEmployeeForm.get('email').errors &&
                                                    (updateEmployeeForm.get('email').dirty || updateEmployeeForm.get('email').touched)"
                                        class="help-block error">

                                        <small
                                          class="text-danger"
                                          *ngIf="updateEmployeeForm.get('email').hasError('required')"
                                          >Employee Email Is Required!
                                        </small>
                                        <small
                                          class="text-danger"
                                          *ngIf="updateEmployeeForm.get('email').hasError('maxlength')">

                                          The maximum length for this field is {{updateEmployeeForm.get('email').errors.maxlength.requiredLength}} characters.
                                        </small>
                                      </div>

                                  </div>

                                  <div class="form-group">
                                      <label for="exampleInputuname" class="col-sm-3 control-label">Phone
                                          number</label>
                                      <div class="col-sm-9">
                                          <div class="input-group">
                                              <input formControlName="phoneNumber" [(ngModel)]="employeeData.phoneNumber" type="text" class="form-control"
                                                  placeholder="Enter Phone number" name="phoneNumber">
                                              <div class="input-group-addon"><i class="fas fa-stream"></i>
                                              </div>
                                          </div>
                                      </div>

                                      <div
                                        *ngIf="updateEmployeeForm.get('phoneNumber').invalid && updateEmployeeForm.get('phoneNumber').errors &&
                                                    (updateEmployeeForm.get('phoneNumber').dirty || updateEmployeeForm.get('phoneNumber').touched)"
                                        class="help-block error">

                                        <small
                                          class="text-danger"
                                          *ngIf="updateEmployeeForm.get('phoneNumber').hasError('required')"
                                          >Employee Phone Number Is Required!
                                        </small>
                                        <small
                                          class="text-danger"
                                          *ngIf="updateEmployeeForm.get('phoneNumber').hasError('maxlength')">

                                          The maximum length for this field is {{updateEmployeeForm.get('phoneNumber').errors.maxlength.requiredLength}} characters.
                                        </small>
                                      </div>

                                  </div>
                              </form>
                          </div>
                          <div class="modal-footer">
                              <button type="submit" class="btn btn-info waves-effect"
                                  data-dismiss="modal" (click)="onUpdateEmployee()" [disabled]="!updateForm.valid">Save Changes</button>
                          </div>
                      </div>
                      <!-- /.modal-content -->
                  </div>
                  <!-- /.modal-dialog -->
              </div>

          </div>

      </div>
      <br>

      <div class="table-responsive manage-table m-l-20 m-r-20" style="display:flex; flex-direction: column; ">

        <div class=" pull-right">
            <mat-form-field>
                <input
                  (keyup)='applyFilter()'
                  [(ngModel)]="searchKey"
                  type="text"
                  matInput
                  placeholder="Search"
                  autocomplete="off">
            </mat-form-field>
        </div>


          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="flex: 1;">

            <!-- Employee Column -->
            <ng-container matColumnDef="employee">
                <th mat-header-cell *matHeaderCellDef class="table-header"> Employee </th>
                <td mat-cell *matCellDef="let employee"> {{employee.employeeName}} </td>
            </ng-container>


            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                <td mat-cell *matCellDef="let employee" style="display:flex; flex-direction: row; justify-content:between; flex:wrap; margin-left:20px; align-items:center; margin-top:5px;">

                  <button class="btn view-botton"
                        [routerLink]="['/individualEmployeeView', employee._id]">View</button>

                  <button class="btn btn-primary m-l-10" data-toggle="modal"
                        data-target="#employeeUpdateModel"
                        (click)="setUpdateEmployeeData(employee)">Edit</button>

                  <button class="btn btn-danger m-l-10"
                        (click)="onDeleteEmployee(employee)">Remove</button> </td>
            </ng-container>

            <!-- Footer -loading & nodata-->
            <ng-container matColumnDef="loading">
                <td mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </td>
            </ng-container>

            <ng-container matColumnDef="noData">
                <td mat-footer-cell *matFooterCellDef colspan="6"> No data. </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"
                class="footer-row"></tr>

            <tr mat-footer-row *matFooterRowDef="['noData']"
                [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length ==0)}" class="footer-row"></tr>
        </table>
        <div class="paginator-responsive3">
            <mat-paginator  #paginator [pageSizeOptions]='[5]' showFirstLastButtons style="flex: 1;"></mat-paginator>
           </div>
      </div>

  </div>
  <!-- /.container-fluid -->
</div>
