<div id="wrapper">

 
    <div class="navbar-default sidebar" role="navigation">
        <div class="sidebar-nav slimscrollsidebar">
            <div class="sidebar-head">
                <h3>
                    <span class="fa-fw open-close">
                        <i class="fas fa-bars hidden-xs"></i>
                        <i class="fas fa-bars " style="margin-left:-20px"></i>
                    </span>
                    <span class="hide-menu hidden-xs">Efito Inventory</span>
                </h3>
            </div>
            <ul class="nav" id="side-menu">

                <li>
                    <a routerLink='/inventory' class="waves-effect" routerLinkActive="active"><span class="fa-fw"><i
                                class="fas fa-boxes "></i></span>

                        <span class="hide-menu"> Inventory </span></a>
                </li>
                <li>
                    <a routerLink='/product-allocation' class="waves-effect" routerLinkActive="active"><span
                            class="fa-fw"><i class="fas fa-tasks "></i></span>
                        <span class="hide-menu">Product Allocation</span>
                    </a>
                </li>
                <li>
                    <a routerLink='/availableProducts' class="waves-effect" routerLinkActive="active"><span
                            class="fa-fw"><i class="fas fa-check-square"></i></span>
                        <span class="hide-menu">Available Products</span>
                    </a>
                </li>
                <li>
                    <a routerLink='/employeeList' class="waves-effect" routerLinkActive="active"><span class="fa-fw"><i
                                class="fas fa-users "></i></span>
                        <span class="hide-menu">Employee </span>
                    </a>
                </li>
                <li>
                    <a routerLink='/category' class="waves-effect" routerLinkActive="active"><span class="fa-fw"><i
                                class="fas fa-th-list "></i></span>
                        <span class="hide-menu">Category</span>
                    </a>
                </li>
                <li class="devider"></li>
            </ul>
        </div>
    </div>




    <router-outlet></router-outlet>
    
    <footer class="footer text-center">
        2021 &copy; Efito Solutions | <a href="https://efitosolutions.com/" target="_blank">efitosolutions.com</a>
    </footer>


</div>
