import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Category } from 'src/app/models/category-model';

@Injectable({
  providedIn: 'root',
})
export class SweetalertService {
  constructor() {}

  public static error(error: string) {
    Swal.fire(error, '', 'error');
  }
  public static success(msg: string) {
    Swal.fire(msg, '', 'success');
  }

  public static deleteConfirmation(deleteFunction, category) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        deleteFunction(category);
      }
    });
  }
}
