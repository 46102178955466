import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Util } from 'src/app/common/utill';
import { InventoryService } from 'src/app/services/inventory.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';

@Component({
  selector: 'app-item-description',
  templateUrl: './item-description.component.html',
  styleUrls: ['./item-description.component.css'],
})
export class ItemDescriptionComponent implements OnInit {
  inventoryService: InventoryService;
  item: any = {};
  uploads: any[] = [];
  label: any;
  id: any;
  categoryId: any;
  categoryName: string;
  uploadItemData: any;
  filePath: string = Util.apiPublicFile();

  constructor(
    inventoryService: InventoryService,
    public route: ActivatedRoute
  ) {
    this.inventoryService = inventoryService;
  }

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
        this.inventoryService
          .getIndividualItemDiscription(this.id)
          .subscribe((res) => {
            // console.log(res.data)
            this.item = {
              brand: res.data.mainData.brand,
              id: res.data.mainData.label,
              description: res.data.mainData.description,
              price: res.data.mainData.price,
              purchaseDate: res.data.mainData.purchaseDate,
            };

            res.data.uploads.forEach((uploadItem) => {
              // console.log(uploadItem)
              this.uploadItemData = {
                name: uploadItem.name,
                type: uploadItem.type,
                originalName: uploadItem.originalName,
                itemId: uploadItem.itemId,
                path: `${this.filePath}${uploadItem.name}`,
              };
              this.uploads.push(this.uploadItemData);
            });
            // console.log(this.uploads);
            // this.uploads = res.data.uploads;
            this.categoryName = res.data.mainData.category.categoryType;
            this.categoryId = res.data.mainData.category._id;
          });
      } else {
        return SweetalertService.error('No Item Found');
      }
    });
  }

  onImage(url: any) {
    window.open(url, '_blank');
  }
}
