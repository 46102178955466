import { Pipe, PipeTransform } from '@angular/core';
import { Item } from '../models/item-model';

@Pipe({
  name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {

  transform(items: Item[], searchText: string): any[] {
    if(!items || ! searchText) {
      return items;
    }
    searchText = searchText.toLocaleLowerCase();

    return items.filter(item => item.brand.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
  }

}
