<div id="page-wrapper">
    <div class="container-fluid">



        <!-- ============================================================== -->
        <!-- wallet, & manage users widgets -->
        <!-- ============================================================== -->
        <!-- .row -->
        <div>
            <ol class="breadcrumb breadcrumb-list">
                <li><a>Product Allocation</a></li>

            </ol>
        </div>

        <div class="row  m-l-5">

            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Select Employee*</mat-label>
                    <input type="text" placeholder="Choose Employee" aria-label="AllocatedPerson" matInput
                        [formControl]="employeeControl" [matAutocomplete]="autoEmployee" #employee>
                    <mat-autocomplete autoActiveFirstOption #autoEmployee="matAutocomplete"
                        (optionSelected)="selectedEmployee($event.option.value)" [displayWith]="displayEmployeeName">
                        <mat-option *ngFor="let employee of allEmployees | async" [value]="employee">
                            {{employee.employeeName}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Select Category*</mat-label>
                    <input type="text" class="form-searchable" placeholder="Choose Category" aria-label="Category"
                        matInput [formControl]="categoryControl" [matAutocomplete]="autoCategory"
                        (change)="onItemInputChange($event.target.value)">
                    <mat-autocomplete autoActiveFirstOption #autoCategory="matAutocomplete"
                        (optionSelected)="selectedCategory($event.option.value)" [displayWith]="displayCategoryName">
                        <mat-option *ngFor="let category of allCategories | async" [value]="category">
                            {{category.categoryType}}
                        </mat-option>
                    </mat-autocomplete>
                    <!-- <mat-error *ngIf="addItemForm.get('category').invalid">Please enter post title.</mat-error> -->
                </mat-form-field>
            </div>
            <div class="col-lg-2">
                <mat-form-field class="example-full-width">
                    <mat-label>Select Product Item*</mat-label>
                    <input type="text" class="form-searchable" placeholder="Choose Product Item" aria-label="Item"
                        matInput [formControl]="itemControl" [matAutocomplete]="autoItem" #productItem>
                    <mat-autocomplete autoActiveFirstOption #autoItem="matAutocomplete"
                        (optionSelected)="selectedItem($event.option.value)">
                        <mat-option *ngFor="let item of allItems | async" [value]="item.label">
                            {{item.label}}
                        </mat-option>
                    </mat-autocomplete>
                    <!-- <mat-error *ngIf="addItemForm.get('category').invalid">Please enter post title.</mat-error> -->
                </mat-form-field>
            </div>

            <div class="col-lg-2 mb-3">
                <button href="#" target="" class="btn btn-primary  waves-effect waves-light m-r-20"
                    (click)="allocatedProduct()"
                    [disabled]="!(productAllocateObj.allocatedPerson && productAllocateObj.label)">
                    Allocate Item</button>

            </div>

        </div>


        <div class="table-responsive manage-table m-l-20 m-r-20 ng-table-responsive" style="display:flex; flex-direction: column; margin-top:10px">
            <div class=" pull-right">
                <mat-form-field>
                    <input
                      (keyup)='applyFilter()'
                      [(ngModel)]="searchKey"
                      type="text"
                      matInput
                      placeholder="Search"
                      autocomplete="off">
                </mat-form-field>
            </div>


            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #myTable style="flex: 1;">

                <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

                <!-- Position Column -->
                <ng-container matColumnDef="product">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Category </th>
                    <td mat-cell *matCellDef="let element"> {{element.category.categoryType}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="product-item">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Product Item </th>
                    <td mat-cell *matCellDef="let element"> {{element.label}} </td>
                </ng-container>

                <!-- Weight Column -->
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> User </th>
                    <td mat-cell *matCellDef="let element"> {{element.allocatedPersonId.employeeName}} </td>
                </ng-container>

                <!-- Symbol Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                    <td mat-cell *matCellDef="let element"> <button class="btn btn-danger" style="margin-left:10px"
                            (click)="deallocatedProduct(element)">Deallocate </button> </td>
                </ng-container>

                <!-- Footer -loading & nodata-->
                <ng-container matColumnDef="loading">
                    <td mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </td>
                </ng-container>
                <ng-container matColumnDef="noData">
                    <td mat-footer-cell *matFooterCellDef colspan="6"> No data. </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"
                    class="footer-row"></tr>
                <tr mat-footer-row *matFooterRowDef="['noData']"
                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length ==0)}" class="footer-row"></tr>
            </table> 
               <div class="paginator-responsive2">
                <mat-paginator  #paginator [pageSizeOptions]='[5]' showFirstLastButtons style="flex: 1;"></mat-paginator>
               </div>

        </div>

    </div>
</div>
