<div id="page-wrapper">
  <div class="container-fluid">



      <!-- ============================================================== -->
      <!-- wallet, & manage users widgets -->
      <!-- ============================================================== -->
      <!-- .row -->
      <div>
          <ol class="breadcrumb breadcrumb-list">
              <li ><a routerLink=''>Inventory</a></li>
              <li *ngIf="itemCategory"><a>{{itemCategory}}</a></li>

          </ol>
      </div>

      <div class="row">
          <!-- <div class="col-lg-3 col-md-4 col-sm-4 col-xs-12 ">
              <input type="text" class="form-control  m-l-20" [(ngModel)]="searchText" placeholder="Search Item">
          </div> -->
          <div class="col-lg-9 col-sm-8 col-md-8 pull-right">
              <a routerLink='/addItem' target=""
                  class="btn btn-primary pull-right m-l-20  waves-effect waves-light m-r-20">
                  Add Item</a>

          </div>
          <!-- /.col-lg-12 -->
      </div>
      <br>
      <h1 class="m-l-20"></h1>
      <div class="table-responsive manage-table  m-l-20 m-r-20" >

          <div class=" pull-right">
            <mat-form-field>
                <input
                  (keyup)='applyFilter()'
                  [(ngModel)]="searchKey"
                  type="text"
                  matInput
                  placeholder="Search"
                  autocomplete="off">
            </mat-form-field>
          </div>


          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #myTable style="flex: 1;">

            <!-- Brand Column -->
            <ng-container matColumnDef="brand">
                <th mat-header-cell *matHeaderCellDef class="table-header"> Brand </th>
                <td mat-cell *matCellDef="let item"> {{ item.brand }} </td>
            </ng-container>

            <!-- Id Column -->
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef class="table-header"> Id </th>
              <td mat-cell *matCellDef="let item"> {{ item.label }} </td>
            </ng-container>

            <!-- Allocated Person Column -->
            <ng-container matColumnDef="allocatedPerson">
              <th mat-header-cell *matHeaderCellDef class="table-header"> Allocated Person </th>
              <td mat-cell *matCellDef="let item">
                <p *ngIf="item.allocatedPersonId">{{ item.allocatedPersonId.employeeName }}</p>
                <p *ngIf="!item.allocatedPersonId">-</p>
              </td>
            </ng-container>

            <!-- status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef class="table-header"> Status </th>
              <td mat-cell *matCellDef="let item">
                <p *ngIf="item.status==='ALLOCATED'" class="text-danger">{{ item.status }}</p>
                <p *ngIf="item.status==='AVAILABLE'" class="text-success">{{ item.status }}</p>
              </td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                <td mat-cell *matCellDef="let item" style="display:flex; flex-direction: row; justify-content:between; flex:wrap; margin-left:20px; align-items:center; margin-top:5px;">

                  <button class="btn view-botton"
                    [routerLink]="['/itemDescription', item._id]">View</button>

                  <button class="btn btn-primary m-l-10"
                    [routerLink]="['/edit', item._id]">Edit</button>

                  <button class="btn btn-danger m-l-10"
                    (click)="onDeleteItem(item)">Remove</button>
                </td>
            </ng-container>

            <!-- Footer -loading & nodata-->
            <ng-container matColumnDef="loading">
                <td mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </td>
            </ng-container>
            <ng-container matColumnDef="noData">
                <td mat-footer-cell *matFooterCellDef colspan="6"> No data. </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!=null}"
                class="footer-row"></tr>
            <tr
                mat-footer-row
                *matFooterRowDef="['noData']"
                [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length ==0)}"
                class="footer-row"></tr>
        </table>
        <div class="paginator-responsive1">
          <mat-paginator  #paginator [pageSizeOptions]='[5]' showFirstLastButtons style="flex: 1;"></mat-paginator>
         </div>
      </div>
  </div>
  <!-- /.container-fluid -->
</div>
