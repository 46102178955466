import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Category } from 'src/app/models/category-model';
import { startWith, map } from 'rxjs/operators';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { ProductAllocationService } from 'src/app/services/product-allocation.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { Employee } from 'src/app/models/employee-model';
import { EmployeeService } from 'src/app/services/employee.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { Item } from 'src/app/models/item-model';

@Component({
  selector: 'app-product-allocation',
  templateUrl: './product-allocation.component.html',
  styleUrls: ['./product-allocation.component.css'],
})
export class ProductAllocationComponent implements OnInit {
  //product allocation variables
  productAllocateObj = {
    label: '',
    allocatedPerson: '',
    category: '',
  };

  allocatedItemList: Item[] = null;

  displayedColumns: string[] = ['product', 'product-item', 'user', 'action'];
  dataSource: any;

  categoryList: any[] = [];
  allCategories: Observable<Category[]>;
  employeeList: Employee[] = [];
  allEmployees: Observable<Employee[]>;
  itemList: Item[] = [];
  allItems: Observable<Item[]>;

  categoryControl = new FormControl();
  employeeControl = new FormControl();
  itemControl = new FormControl();

  searchKey: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('myTable') myTable: MatTable<any>;

  constructor(
    private productAllocationServices: ProductAllocationService,
    private categoryService: CategoryService,
    private employeeService: EmployeeService,
    private inventoryService: InventoryService
  ) {}

  ngOnInit(): void {
    this.itemControl.disable();

    this.categoryService.getAllCategories().subscribe((categories) => {
      this.categoryList = categories.data;

      this.allCategories = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterCategory(value))
      );
    });

    this.employeeService.getAllEmployees().subscribe((employees) => {
      this.employeeList = employees.data;
      this.allEmployees = this.employeeControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterEmployee(value))
      );
    });
    this.getAllocatedItemData();
  }

  private getAllocatedItemData() {
    this.inventoryService.getAllocatedInventory().subscribe(
      (res) => {
        this.allocatedItemList = res.data.reverse();
        this.dataSource = new MatTableDataSource(this.allocatedItemList);

        this.dataSource.filterPredicate = (data: any, filter: string) => {
          return data.category.categoryType.toLocaleLowerCase().includes(filter)
            || data.allocatedPersonId.employeeName.toLocaleLowerCase().includes(filter)
            || data.label.toLocaleLowerCase().includes(filter);
        };

        this.dataSource.paginator = this.paginator;
      },
      (err) => {
        SweetalertService.error('Server Error!');
      }
    );
  }

  private filterCategory(catType: string): Category[] {
    // const filterValue = catType.toLowerCase();
    const filterValue = catType;
    return this.categoryList.filter((cat) =>
      cat.categoryType.toLowerCase().includes(filterValue)
    );
  }
  
  private filterEmployee(employeeName: string): Employee[] {
    // const filterValue = employeeName.toLowerCase();
    const filterValue = employeeName
    // console.log(filterValue)
    return this.employeeList.filter((employee) =>
      employee.employeeName.toLowerCase().includes(filterValue)
    );
  }

  private filterItem(itemName: string): Item[] {
    // const filterValue = itemName.toLowerCase();
    const filterValue = itemName;
    return this.itemList.filter((item) =>
      item.label.toLowerCase().includes(filterValue)
    );
  }

  selectedCategory(category): void {
    this.productAllocateObj.category = category;
    this.itemControl.enable();
    this.inventoryService.getAvailableInventory().subscribe(
      (item) => {
        this.itemList = item.data.filter((oneItem) => {
          return oneItem.categoryId === category._id;
        });
        if (this.itemList.length <= 0) {
          this.itemControl.disable();
        }

        this.allItems = this.itemControl.valueChanges.pipe(
          startWith(''),
          map((value) => this.filterItem(value))
        );
      },
      (err) => {
        SweetalertService.error('Server Error!');
      }
    );
  }

  selectedEmployee(employee): void {
    this.productAllocateObj.allocatedPerson = employee._id;
  }
  selectedItem(item): void {
    this.productAllocateObj.label = item;
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }

  allocatedProduct() {
    this.productAllocationServices
      .allocateProduct(this.productAllocateObj)
      .subscribe(
        (res) => {
          if (res.success === true) {
            SweetalertService.success('Product Allocated');
            this.getAllocatedItemData();
            this.categoryService.getAllCategories().subscribe((categories) => {
              this.categoryList = categories.data;

              this.allCategories = this.categoryControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.filterCategory(value))
              );
            });

            this.employeeService.getAllEmployees().subscribe((employees) => {
              this.employeeList = employees.data;
              this.allEmployees = this.employeeControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.filterEmployee(value))
              );
            });

            this.itemControl.reset();
            this.itemControl.disable();
            this.categoryControl.reset();
            this.employeeControl.reset();
            this.productAllocateObj.allocatedPerson = '';
            this.productAllocateObj.category = '';
            this.productAllocateObj.label = '';
          } else {
            SweetalertService.error(res.error);
          }
        },
        (err) => {
          SweetalertService.error('Server Error!');
        }
      );
  }

  deallocatedProduct(element) {
    this.productAllocationServices.deallocateProduct(element.label).subscribe(
      (res) => {
        if (res.success === true) {
          SweetalertService.success('Product Deallocated');
          this.getAllocatedItemData();

          this.itemControl.reset();
          this.itemControl.disable();
          this.categoryControl.reset();
          this.employeeControl.reset();
          this.productAllocateObj.allocatedPerson = '';
          this.productAllocateObj.category = '';
          this.productAllocateObj.label = '';
        } else {
          SweetalertService.error(res.error);
        }
      },
      (err) => {
        SweetalertService.error('Server Error!');
      }
    );
  }

  displayEmployeeName(subject) {
    return subject ? subject.employeeName : undefined;
  }

  displayCategoryName(subject) {
    return subject ? subject.categoryType : undefined;
  }

  onItemInputChange(value) {
    if (value === '') {
      this.itemControl.disable();
    }
  }
}
