import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Subject } from 'rxjs/internal/Subject';
import { filter } from 'rxjs/operators';
import { Item } from 'src/app/models/item-model';
import { InventoryService } from 'src/app/services/inventory.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inventory-details',
  templateUrl: './inventory-details.component.html',
  styleUrls: ['./inventory-details.component.css']
})
export class InventoryDetailsComponent implements OnInit {

  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = [
    'brand',
    'id',
    'allocatedPerson',
    'status',
    'action'];

  items: Item[] = [];
  searchText: string = '';
  id: any;
  updateItemData: Item;
  ItemData: Item;

  itemCategory: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private inventoryService: InventoryService, public route: ActivatedRoute) {
  }

  ngOnInit() {

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
      } else {
        return SweetalertService.error('Server error!');
      }
    });

    this.inventoryService.refreshNeeded.subscribe(() => {
      this.getIndividualInventory(this.id);
    });
    this.getIndividualInventory(this.id);
  }

  getIndividualInventory(id: any) {
    this.inventoryService.getIndividualInventory(id).subscribe(
      (res) => {
        if (res.success) {
          // console.log(res.data)

          this.items = res.data.reverse();
          this.dataSource = new MatTableDataSource(this.items);
         this.itemCategory? this.itemCategory = res.data[0].category.categoryType : "";


          // this.dataSource.filterPredicate = (data: any, filter: string) => {
          //   return data.brand.toLocaleLowerCase().includes(filter)
          //     || data.allocatedPersonId.employeeName.toLocaleLowerCase().includes(filter)
          //     || data.label.toLocaleLowerCase().includes(filter);
          // };

          this.dataSource.paginator = this.paginator;
        } else {
          SweetalertService.error(res.error)
        }
      }
    );
  }

  onDeleteItem(item: Item) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success')
        this.deleteFunction(item);
      }
    });
  }

  deleteFunction(item: Item) {
    this.inventoryService.deleteItem(item).subscribe(
      (res) => {
        if (res.success) {
          SweetalertService.success(res.message);
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}
