<div id="page-wrapper">
  <div class="container-fluid">


    <!-- ============================================================== -->
    <!-- wallet, & manage users widgets -->
    <!-- ============================================================== -->
    <!-- .row -->
    <div>
      <ol class="breadcrumb breadcrumb-list">
        <li><a routerLink=''>Inventory</a></li>
        <li><a [routerLink]="['/inventoryDetails', categoryId]">{{category}}</a></li>
        <li><a>Edit Items</a></li>

      </ol>
    </div>

    <br>
    <div class="col-md-6">
      <div class="white-box">
        <b style="font-size:24px; margin-left: -15px; margin-bottom:25px">Edit Item</b>
        <br><br>
        <form class="form-horizontal" [formGroup]="editItemForm" #updateForm="ngForm">

          <div class="form-group flex-group">
            <label id="category_label" for="category_label" class="">Category </label>
              <div class="input-group flex-group-input">
                <mat-form-field class="">
                  <mat-label>Select Category</mat-label>
                  <input
                    type="text"
                    formControlName="category"
                    placeholder="Choose One"
                    aria-label="Category"
                    matInput
                    [formControl]="categoryControl"
                    [matAutocomplete]="auto"
                    [(ngModel)]="itemData.category"
                    >
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="selectedCategory($event.option.value)">
                    <mat-option *ngFor="let category of allCategories | async" [value]="category.categoryType">
                      {{category.categoryType}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
               
            </div>

            <div
              *ngIf="editItemForm.get('category').invalid && editItemForm.get('category').errors &&
                          (editItemForm.get('category').dirty || editItemForm.get('category').touched)"
              class="help-block error">
              <small
                class="text-danger"
                *ngIf="editItemForm.get('category').hasError('required')"
                >Category name is required!
              </small>
            </div>

          </div>

          <div class="form-group flex-group">
            <label for="exampleInputEmail1" class="">Brand </label>
              <div class="input-group flex-group-column">
                <input formControlName="brand" [(ngModel)]="itemData.brand" type="text" class="form-control "
                  placeholder="Enter Brand">
                <!-- <div class="input-group flex-group-input-addon"><i class="fab fa-bandcamp"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="editItemForm.get('brand').invalid && editItemForm.get('brand').errors &&
                          (editItemForm.get('brand').dirty || editItemForm.get('brand').touched)"
              class="help-block error">
              <small
                class="text-danger"
                *ngIf="editItemForm.get('brand').hasError('required')"
                >Label is required!
              </small>
              <small
                class="text-danger"
                *ngIf="editItemForm.get('brand').hasError('maxlength')">
                The maximum length for this field is {{editItemForm.get('brand').errors.maxlength.requiredLength}} characters.
              </small>
            </div>
         

          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Label ID *</label>
              <div class="input-group flex-group-input">
                <input formControlName="label" [(ngModel)]="itemData.label" type="text" class="form-control "
                  placeholder="Enter Label ID">
                <!-- <div class="input-group flex-group-input-addon"><i class="fas fa-tags"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="editItemForm.get('label').invalid && editItemForm.get('label').errors &&
                          (editItemForm.get('label').dirty || editItemForm.get('label').touched)"
              class="help-block error">
              <small
                class="text-danger"
                *ngIf="editItemForm.get('label').hasError('required')"
                >Label is required!
              </small>
              <small
                class="text-danger"
                *ngIf="editItemForm.get('label').hasError('maxlength')">
                The maximum length for this field is {{editItemForm.get('label').errors.maxlength.requiredLength}} characters.
              </small>
          </div>
          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Description *</label>

              <div class="input-group flex-group-input">
                <input formControlName="description" [(ngModel)]="itemData.description" type="text"
                  class="form-control " placeholder="Enter Description">
                <!-- <div class="input-group flex-group-input-addon"><i class="fas fa-info-circle"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="editItemForm.get('description').invalid && editItemForm.get('description').errors &&
                          (editItemForm.get('description').dirty || editItemForm.get('description').touched)"
              class="help-block error">
              <small
                class="text-danger"
                *ngIf="editItemForm.get('description').hasError('required')"
                >Description is required!
              </small>
              <small
                class="text-danger"
                *ngIf="editItemForm.get('description').hasError('maxlength')">
                The maximum length for this field is {{editItemForm.get('description').errors.maxlength.requiredLength}} characters.
              </small>
          </div>

          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Price (Rs.) *</label>
              <div class="input-group flex-group-input">
                <input formControlName="price" [(ngModel)]="itemData.price" type="text" class="form-control "
                  laceholder="Enter Price">
                <!-- <div class="input-group flex-group-input-addon"><i class="fas fa-hand-holding-usd"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="editItemForm.get('price').invalid && editItemForm.get('price').errors &&
                          (editItemForm.get('price').dirty || editItemForm.get('price').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="editItemForm.get('price').hasError('required')"
                >Price is required!
              </small>
              <small
                class="text-danger"
                *ngIf="editItemForm.get('price').hasError('maxlength')">
                The maximum length for this field is {{editItemForm.get('price').errors.maxlength.requiredLength}} characters.
              </small>
          </div>

          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Uploads</label>
              <div class="input-group flex-group-input">
                <input
                  formControlName="uploads"
                  [(ngModel)]="itemData.uploads"
                  type="file"
                  class="form-control "
                  placeholder="Enter Website Name"
                  #upload
                  id="upload"
                  (change)="onFileUpload($event.target.files)"
                  multiple
                  accept="image/*, application/pdf">

                  <div class="table-container-files">

                  <table class="table table-bordered" *ngIf="uploadFiles">
                    <tbody>
                      <tr *ngFor="let file of uploadFiles; index as i;" [attr.data-index]="i">
                        <td [ngSwitch]="selectedValue" class="file-row">
                          <div [ngSwitch]="file.type" class="file-icon">
                            <i class="fas fa-file-pdf" *ngSwitchCase="'application/pdf'"></i>
                            <i class="fas fa-file" *ngSwitchDefault></i>
                            <i class="far fa-file-image" *ngSwitchCase="'image/png'"></i>
                            <i class="far fa-file-image" *ngSwitchCase="'image/jpeg'"></i>
                          </div>
                          <div> {{file.name | slice:0:20}}</div>
                          <div class="file-icon-delete"><i class="far fa-times-circle" (click)="deleteTempFile(i)"></i>
                          </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--  <div class="row inline-photos input-field image-container">
                  <div class="col-xs-4 image-el" *ngFor="let file of fileList">
                    <i class="fas fa-trash delete-file" (click)="onDeleteUpload(file._id)"></i>
                    <i class="fas fa-eye view-file" (click)="viewFile(file.name)"></i>
                    <img class="img-responsive" alt="user" src={{filePath+file.name}}>
                  </div>

                </div> -->
                <!-- <div class="input-group flex-group-input-addon"><i class="fas fa-file-upload"></i></div> -->
              </div>
          </div>

          <div class="form-group flex-group" >
            <label for="inputEmail3" class="">Uploaded Files</label>
              <div class="input-group flex-group-input">


                <div class="row inline-photos input-field image-container">
                  <div class="col-xs-4 image-el" *ngFor="let file of fileList">
                    <i class="fas fa-trash delete-file" (click)="onDeleteUpload(file._id)"></i>
                    <i class="fas fa-eye view-file" (click)="viewFile(file.name)"></i>
                    <img class="img-responsive" alt="user" src={{filePath+file.name}} *ngIf="file.type!=='application/pdf'">
                    <img class="img-responsive" alt="user"
                      src="https://akm-img-a-in.tosshub.com/indiatoday/images/story/202008/PDF_password_0.png?LaA5mWQu0OWJXEMVp9dyAuDR8UH0mYiY&size=770:433"
                      *ngIf="file.type=='application/pdf'">
                  </div>

                </div>
                <!-- <div class="input-group flex-group-input-addon"><i class="fas fa-file-upload"></i></div> -->
              </div>
          </div>

          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Allocated Person</label>
              <div class="input-group flex-group-input">
                <mat-form-field class="">
                  <mat-label>Select Employee</mat-label>
                  <input type="text" placeholder="Choose One" aria-label="AllocatedPerson" matInput
                    formControlName="allocatedPerson" [formControl]="employeeControl" [matAutocomplete]="auto1"
                    [(ngModel)]="itemData.allocatedPerson">
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                    (optionSelected)="selectedEmployee($event.option.value)">
                    <mat-option *ngFor="let employee of allEmployees | async" [value]="employee.employeeName">
                      {{employee.employeeName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <div class="input-group flex-group-input-addon"><i class="fas fa-hand-holding-usd"></i></div> -->
              </div>
            </div>

          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Purchase Date *</label>
              <div class="input-group flex-group-input">
                <input formControlName="date" [(ngModel)]="itemData.purchaseDate" type="date"
                  class="form-control " placeholder="Enter Website Name">
                <!-- <div class="input-group flex-group-input-addon"><i class="far fa-calendar-alt"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="editItemForm.get('date').invalid && editItemForm.get('date').errors &&
                          (editItemForm.get('date').dirty || editItemForm.get('date').touched)"
              class="help-block error">
              <small
                class="text-danger"
                *ngIf="editItemForm.get('date').hasError('required')"
                >Date is required!
              </small>
          </div>

          <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>

          <div class="form-group flex-group m-b-0">
            
              <button type="submit" class="btn  btn-info waves-effect waves-light m-t-10" [disabled]="!updateForm.valid"
                (click)="onEditItem()">Save Changes
              </button>
        
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
