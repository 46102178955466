import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Employee } from 'src/app/models/employee-model';
import { EmployeeService } from 'src/app/services/employee.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-employee',
  templateUrl: './view-employee.component.html',
  styleUrls: ['./view-employee.component.css']
})
export class ViewEmployeeComponent implements OnInit {
  data: any = {};
  id: any;
  allocatedItems: any[] = [];
  allocatedPerson: any;

  constructor(private employeeService: EmployeeService, public route: ActivatedRoute) { }

  ngOnInit()  {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');

        this.employeeService.refreshNeeded.subscribe(() => {
          this.getEmployeeView(this.id);
        });
        this.getEmployeeView(this.id);
      } else {
        SweetalertService.error('Server error!');
      }
    });
  }


  getEmployeeView(id: string) {
    this.employeeService.getIndividualEmployee(id).subscribe(
      (res) => {
        if (res.success) {
          this.allocatedItems =  res.data.allocatedItems;
          this.allocatedPerson = {
            employeeName: res.data.allocatedPersonInformation.employeeName,
            email: res.data.allocatedPersonInformation.email,
            phoneNmber:  res.data.allocatedPersonInformation.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
          }
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  onRemove(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!',
    }).then((result) => {
      if (result.isConfirmed) {

        this.removeFuction(id);
        Swal.fire('Removed!', 'Item is deallocated.', 'success');
      }
    })
  }

  removeFuction(id: string) {
    this.employeeService.removeAllocatedItem(id).subscribe(
      (res) => {
        if(res.success) {
          // SweetalertService.success(res.message);
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }
}
