import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Item } from 'src/app/models/item-model';
import { AvailableProductService } from 'src/app/services/available-product.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-available-product-discription',
  templateUrl: './available-product-discription.component.html',
  styleUrls: ['./available-product-discription.component.css']
})
export class AvailableProductDiscriptionComponent implements OnInit {

  dataSource: any;
  searchKey: string;
  displayedColumns: string[] = ['availableItem', 'availableItemId', 'action'];

  id: any;
  items: Item[] = [];
  sortedItems: Item[] = [];
  itemName:string [];
  category: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(
    private availableProductService: AvailableProductService,
    public route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');
      } else {
        return SweetalertService.error('Server error!');
      }
    });

    this.availableProductService.refreshNeeded.subscribe(() => {
      this.getAvailableProductsByCategory(this.id);
    });
    this.getAvailableProductsByCategory(this.id);
  }

  getAvailableProductsByCategory(id: any) {
    this.availableProductService.getAvailableProductsByCategory(id).subscribe(
      (res) => {
        if (res.success) {
          this.items = res.data;
          this.sortedItems = this.items.sort((a, b) => {
            if(a.brand < b.brand) { return -1; }
            if(a.brand > b.brand) { return 1; }
            return 0;
          });
          this.dataSource = new MatTableDataSource(this.sortedItems);

          this.category ? this.category = res.data[0].categoryId.categoryType : "";
          this.dataSource.paginator = this.paginator;
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  onDeleteItem(item: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.deleteFunction(item);
      }
    })
  }

  deleteFunction(item: any) {
    this.availableProductService.deleteItem(item).subscribe(
      (res) => {
        if (res.success) {
          // SweetalertService.success('Item Deleted');
        } else {
          SweetalertService.error(res.error);
        }
      }
    );
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
}

