import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { ConfirmedValidator } from 'src/app/validators/confirmed.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent implements OnInit {
  email: string;
  password: string;
  username: string;

  exform: FormGroup = new FormGroup({});

  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router) {
    this.exform = fb.group(
      {
        username: new FormControl(null, Validators.required),
        email: new FormControl(null, [Validators.required, Validators.email]),
        password: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(4)]),
        confirmPassword: new FormControl(null, [Validators.required, Validators.maxLength(15), Validators.minLength(4)]),
      },
      {
        validators: ConfirmedValidator('password', 'confirmPassword'),
      }
    );
  }

  ngOnInit(): void {}

  onSubmit() {
    const userData: User = {
      email: this.email,
      password: this.password,
      userType: 'ADMIN',
      userName: this.username,
    };
    console.log(userData);
    this.authService.registerUser(userData).subscribe(
      (res) => {
        SweetalertService.success('User created');
        this.exform.reset();
        this.router.navigate(['/login']);
      },
      (err) => {
        SweetalertService.error(err.message);
        console.log(err.message);
      }
    );
  }
}
