<div id="page-wrapper">
  <div class="container-fluid">


    <!-- ============================================================== -->
    <!-- wallet, & manage users widgets -->
    <!-- ============================================================== -->
    <!-- .row -->
    <div>
      <ol class="breadcrumb breadcrumb-list">
        <li><a routerLink=''>Inventory</a></li>
        <!-- <li><a routerLink='/'>Monitors</a></li> -->
        <li><a>Add Items</a></li>

      </ol>
    </div>


    <br>
    <div class="col-md-6">
      <div class="white-box">
        <b style="font-size:24px; margin-left: -15px; margin-bottom:25px">Add Item</b>
        <br><br>
        <form class="form-horizontal" [formGroup]="addItemForm" #itemForm="ngForm">

          <div class="form-group  flex-group" >
            <label id="category_label" for="category_label" class="">Category </label>
       
              <div class="input-group flex-group-input">
                <mat-form-field class="">
                  <mat-label>Select Category</mat-label>
                  <input
                    type="text"
                    formControlName="category"
                    class="form-searchable"
                    placeholder="Choose One"
                    aria-label="Category"
                    matInput
                    [formControl]="categoryControl"
                    [matAutocomplete]="auto"
                    [(ngModel)]="itemData.category">
                  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                    (optionSelected)="selectedCategory($event.option.value)">
                    <mat-option *ngFor="let category of allCategories | async" [value]="category.categoryType">
                      {{category.categoryType}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
               
              </div>
            

            <div
              *ngIf="addItemForm.get('category').invalid && addItemForm.get('category').errors &&
                          (addItemForm.get('category').dirty || addItemForm.get('category').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="addItemForm.get('category').hasError('required')"
                >Category name is required!
              </small>
            </div>

          </div>

          <div class="form-group  flex-group" >
            <label for="brand_label" class="">Brand </label>
           
              <div class="input-group flex-group-column" >
                <input 
                  formControlName="brand"
                  [(ngModel)]="itemData.brand" t
                  ype="text"
                  class="form-control "
                  placeholder="Enter Brand">
                <!-- <div class="input-group-addon"><i class="fab fa-bandcamp"></i></div> -->
              </div>
            

            <div
              *ngIf="addItemForm.get('brand').invalid && addItemForm.get('brand').errors &&
                          (addItemForm.get('brand').dirty || addItemForm.get('brand').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="addItemForm.get('brand').hasError('required')"
                >Brand name is required!
              </small>

              <small
                class="text-danger"
                *ngIf="addItemForm.get('brand').hasError('maxlength')">

                The maximum length for this field is {{addItemForm.get('brand').errors.maxlength.requiredLength}} characters.
              </small>
            </div>

          </div>

          <div class="form-group flex-group">
            <label for="id_label" class="">Label ID </label>
           
              <div class="input-group flex-group-input">
                <input formControlName="label" [(ngModel)]="itemData.label" type="text" class="form-control" 
                  placeholder="Enter Label ID">
                <!-- <div class="input-group-addon"><i class="fas fa-tags"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="addItemForm.get('label').invalid && addItemForm.get('label').errors &&
                          (addItemForm.get('label').dirty || addItemForm.get('label').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="addItemForm.get('label').hasError('required')"
                >Label is required!
              </small>

              <small
                class="text-danger"
                *ngIf="addItemForm.get('label').hasError('maxlength')">

                The maximum length for this field is {{addItemForm.get('label').errors.maxlength.requiredLength}} characters.
              </small>
           

          </div>

          <div class="form-group flex-group">
            <label for="description_label" class="">Description *</label>
           
              <div class="input-group flex-group-input">
                <textarea formControlName="description" [(ngModel)]="itemData.description" type="text"
                  class="form-control " placeholder="Enter Description" rows="3"></textarea>
                <!-- <div class="input-group-addon"><i class="fas fa-info-circle"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="addItemForm.get('description').invalid && addItemForm.get('description').errors &&
                          (addItemForm.get('description').dirty || addItemForm.get('description').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="addItemForm.get('description').hasError('required')"
                >Description is required!
              </small>

              <small
                class="text-danger"
                *ngIf="addItemForm.get('description').hasError('maxlength')">

                The maximum length for this field is {{addItemForm.get('description').errors.maxlength.requiredLength}} characters.
              </small>
  

          </div>

          <div class="form-group flex-group">
            <label for="price_label" class="">Price (Rs.) </label>
            
              <div class="input-group flex-group-input">
                <input formControlName="price" [(ngModel)]="itemData.price" type="number"
                  class="form-control " placeholder="Enter Price">
                <!-- <div class="input-group-addon"><i class="fas fa-hand-holding-usd"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="addItemForm.get('price').invalid && addItemForm.get('price').errors &&
                          (addItemForm.get('price').dirty || addItemForm.get('price').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="addItemForm.get('price').hasError('required')"
                >Price is required!
              </small>
              <small
                class="text-danger"
                *ngIf="addItemForm.get('price').hasError('maxlength')">

                The maximum length for this field is {{addItemForm.get('price').errors.maxlength.requiredLength}} characters.
              </small>
            
          </div>

          <div class="form-group flex-group">
            <label for="upload_label" class="">Uploads</label>
           
              <div class="input-group flex-group-input">
                <input
                  formControlName="uploads"
                  [(ngModel)]="itemData.uploads"
                  type="file"
                  placeholder="123"
                  value=""
                  class="form-control "
                  multiple
                  #upload
                  id="upload"
                  (change)="onFileUpload($event.target.files)"
                  accept="image/*, application/pdf">

                <!-- <div class="input-group-addon"><i class="fas fa-file-upload"></i></div> -->
              </div>
              <div class="table-container-files">
                <table class="table table-bordered" *ngIf="uploadFiles">
                  <tbody>
                    <tr *ngFor="let file of uploadFiles; index as i;" [attr.data-index]="i">
                      <td [ngSwitch]="selectedValue" class="file-row">
                        <div [ngSwitch]="file.type" class="file-icon">
                          <i class="fas fa-file-pdf" *ngSwitchCase="'application/pdf'"></i>
                          <i class="fas fa-file" *ngSwitchDefault></i>
                          <i class="far fa-file-image" *ngSwitchCase="'image/png'"></i>
                          <i class="far fa-file-image" *ngSwitchCase="'image/jpeg'"></i>
                        </div>
                        <div> {{file.name | slice:0:20}}</div>
                        <div class="file-icon-delete"><i class="far fa-times-circle" (click)="deleteTempFile(i)"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
          </div>

          <div class="form-group flex-group">
            <label for="allocatePerson_label" class="">Allocated Person</label>
            
              <div class="input-group flex-group-input">
                <mat-form-field >
                  <mat-label>Select Employee</mat-label>
                  <input type="text" class="" formControlName="allocatedPerson" placeholder="Choose One"
                    aria-label="AllocatedPerson" matInput [formControl]="employeeControl" [matAutocomplete]="auto1">
                  <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete"
                    (optionSelected)="selectedEmployee($event.option.value)">
                    <mat-option *ngFor="let employee of allEmployees | async" [value]="employee.employeeName">
                      {{employee.employeeName}}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field>
                <!-- <div class="input-group-addon icon-side"><i class="fas fa-hand-holding-usd"></i></div> -->
              </div>
            
          </div>

          <div class="form-group flex-group">
            <label for="inputEmail3" class="">Purchase Date *</label>
           
              <div class="input-group flex-group-input">
                <input
                  formControlName="date"
                  [(ngModel)]="itemData.purchaseDate"
                  type="date"
                  max="{{maxDate}}"
                  class="form-control "
                  placeholder="Enter Website Name">
                <!-- <div class="input-group-addon"><i class="far fa-calendar-alt"></i></div> -->
              </div>
            </div>

            <div
              *ngIf="addItemForm.get('date').invalid && addItemForm.get('date').errors &&
                          (addItemForm.get('date').dirty || addItemForm.get('date').touched)"
              class="help-block error">

              <small
                class="text-danger"
                *ngIf="addItemForm.get('date').hasError('required')"
                >Date is required!
              </small>

   


          </div>

          <mat-progress-bar mode="indeterminate" *ngIf="isLoading">Loading...</mat-progress-bar>
          <!-- <mat-spinner *ngIf="isLoading"></mat-spinner> -->

          <div class="form-group flex-group " >
            
              <button
                type="button"
                class="btn  btn-danger waves-effect waves-light m-t-10"
                (click)="onCancelItem()">Reset Form
              </button>
              <button type="submit" class="btn  btn-info waves-effect waves-light m-t-10" [disabled]="!itemForm.valid"
                (click)="onAddItem()">Add Item
              </button>
           
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>





<!--Angular Input Form-->

<!-- <div class="form-group flex-group">
  <label id ="category_label" for="category_label" class="">Category *</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Select Category</mat-label>
        <input type="text"
              class="form-searchable"
              placeholder="Choose One"
              aria-label="Category"
              matInput
              [formControl]="categoryControl"
              [matAutocomplete]="auto"
              formControlName="category">
          <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedCategory($event.option.value)">
          <mat-option *ngFor="let category of allCategories | async" [value]="category.categoryType">
            {{category.categoryType}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
  <span
      *ngIf="!addItemForm.get('category').valid && addItemForm.get('category').touched"
      class="help-block error">Category Is Required!</span>
</div>

<div class="form-group flex-group">
  <label for="exampleInputEmail1" class="">Brand *</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Brand</mat-label>
        <input
        matInput
        formControlName="brand"
        [(ngModel)]="itemData.brand"
        type="text"
        class="form-control"
        placeholder="Enter Brand">
      </mat-form-field>
    </div>
  </div>
  <span
      *ngIf="!addItemForm.get('brand').valid && addItemForm.get('brand').touched"
      class="help-block error">Brand Is Required!</span>
</div>

<div class="form-group flex-group">
  <label for="inputEmail3" class="">Label ID *</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Label ID</mat-label>
        <input
        matInput
        formControlName="label"
        [(ngModel)]="itemData.label"
        type="text"
        class="form-control"
        placeholder="Enter Label ID">
      </mat-form-field>
    </div>
  </div>
  <span
      *ngIf="!addItemForm.get('label').valid && addItemForm.get('label').touched"
      class="help-block error">Label Is Required!</span>
</div>

<div class="form-group flex-group">
  <label for="inputEmail3" class="">Description *</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Description</mat-label>
        <textarea
        matInput
        formControlName="description"
        [(ngModel)]="itemData.description"
        type="text"
        class="form-control"
        placeholder="Enter Description"
        rows="3">
      </textarea>
      </mat-form-field>
    </div>
  </div>
  <span
      *ngIf="!addItemForm.get('description').valid && addItemForm.get('description').touched"
      class="help-block error-textarea">Description Is Required!</span>
</div>

<div class="form-group flex-group">
  <label for="inputEmail3" class="">Price *</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Price</mat-label>
        <input
        matInput
        formControlName="price"
        [(ngModel)]="itemData.price"
        type="text"
        class="form-control"
        placeholder="Enter Price">
      </mat-form-field>
    </div>
  </div>
  <span
      *ngIf="!addItemForm.get('price').valid && addItemForm.get('price').touched"
      class="help-block error">Price Is Required!</span>
</div>

<div class="form-group flex-group">
  <label for="inputEmail3" class="">Uploads</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <input formControlName="uploads" [(ngModel)]="itemData.uploads" type="file"
            class="form-control"
            placeholder="Enter Website Name">

      <mat-form-field>
        <mat-label>Uploads</mat-label>
        <input
        matInput
        formControlName="uploads"
        [(ngModel)]="itemData.uploads"
        type="file"
        class="form-control"
        placeholder="Enter Website Name">
      </mat-form-field>
    </div>
  </div>
</div>

<div class="form-group flex-group">
  <label for="inputEmail3" class="">Allocated Person</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Select Employee</mat-label>
        <input type="text"
              placeholder="Choose One"
              aria-label="AllocatedPerson"
              matInput
              [formControl]="employeeControl"
              [matAutocomplete]="auto1">
        <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete" (optionSelected)="selectedEmployee($event.option.value)">
          <mat-option *ngFor="let employee of allEmployees | async" [value]="employee.employeeName">
            {{employee.employeeName}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="form-group flex-group">
  <label for="inputEmail3" class="">Purchase Date *</label>
  <div class="col-sm-9">
    <div class="input-group flex-group-input">
      <mat-form-field class="example-full-width">
        <mat-label>Purchase Date</mat-label>
        <input
        matInput
        formControlName="date"
        [(ngModel)]="itemData.purchaseDate"
        type="date"
        class="form-control"
        placeholder="Enter Website Name">
      </mat-form-field>

    </div>
  </div>
  <span
      *ngIf="!addItemForm.get('date').valid && addItemForm.get('date').touched"
      class="help-block error">Purchase Date Is Required!</span>
</div> -->
