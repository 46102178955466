import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AddItemComponent } from './main/add-item/add-item.component';
import { AvailableProductDiscriptionComponent } from './main/available-product/available-product-discription/available-product-discription.component';
import { AvailableProductComponent } from './main/available-product/available-product.component';
import { CategoryComponent } from './main/category/category.component';
import { EditItemComponent } from './main/edit-item/edit-item.component';
import { EmployeeListComponent } from './main/employee/employee-list/employee-list.component';
import { ViewEmployeeComponent } from './main/employee/view-employee/view-employee.component';
import { InventoryDetailsComponent } from './main/inventory/inventory-details/inventory-details.component';
import { InventoryComponent } from './main/inventory/inventory.component';
import { ItemDescriptionComponent } from './main/item-description/item-description.component';
import { LoginComponent } from './main/login/login.component';
import { MainLayoutComponent } from './main/main-layout/main-layout.component';
import { ProductAllocationComponent } from './main/product-allocation/product-allocation.component';
import { RegisterComponent } from './main/register/register.component';

const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: '/inventory',
        pathMatch: 'full',
      },
      {
        path: 'inventory',
        component: InventoryComponent,
      },
      {
        path: 'inventoryDetails/:id',
        component: InventoryDetailsComponent,
      },
      {
        path: 'addItem',
        component: AddItemComponent,
      },
      {
        path: 'itemDescription/:id', //work
        component: ItemDescriptionComponent,
      },
      {
        path: 'availableProducts',
        component: AvailableProductComponent,
      },
      {
        path: 'availableProductDiscription/:id',
        component: AvailableProductDiscriptionComponent,
      },
      {
        path: 'category',
        component: CategoryComponent,
      },
      {
        path: 'employeeList',
        component: EmployeeListComponent
      },
      {
        path: 'product-allocation',
        component: ProductAllocationComponent,
      },
      {
        path: 'individualEmployeeView/:id',
        component: ViewEmployeeComponent
      },
      {
        path: 'edit/:id',
        component: EditItemComponent
      }
    ],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'register',
    component: RegisterComponent,
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
