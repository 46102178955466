<div id="page-wrapper">
    <div class="container-fluid">

        <!-- ============================================================== -->
        <!-- wallet, & manage users widgets -->
        <!-- ============================================================== -->
        <!-- .row -->
        <div>
            <ol class="breadcrumb">
                <li><a routerLink='/availableProducts'>Available products</a></li>
                <li *ngIf="category"><a>{{category}}</a></li>
            </ol>
        </div>

        <div class="row">
            <div class="col-lg-9 col-sm-8 col-md-8 pull-right">
                <a routerLink='/addItem' target=""
                    class="btn btn-primary pull-right m-l-20 hidden-xs hidden-sm waves-effect waves-light m-r-20">
                    Add Item</a>

            </div>
            <!-- /.col-lg-12 -->
        </div>
        <br>
        <h1 class="m-l-20">{{category}}</h1>
        <div class="table-responsive manage-table  m-l-20 m-r-20">

            <div class=" pull-right">
              <mat-form-field>
                  <input (keyup)='applyFilter()' [(ngModel)]="searchKey" type="text" matInput placeholder="Search"
                      autocomplete="off">
              </mat-form-field>
            </div>


            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #myTable>

              <!-- Item Column -->
              <ng-container matColumnDef="availableItem">
                  <th mat-header-cell *matHeaderCellDef class="table-header"> Brand </th>
                  <td mat-cell *matCellDef="let item"> {{ item.brand }} </td>
              </ng-container>

              <!-- Id Column -->
              <ng-container matColumnDef="availableItemId">
                <th mat-header-cell *matHeaderCellDef class="table-header"> Product Id </th>
                <td mat-cell *matCellDef="let item"> {{ item.label }} </td>
              </ng-container>


              <!-- Action Column -->
              <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="table-header"> Action </th>
                  <td mat-cell *matCellDef="let item">

                    <button class="btn view-botton" [routerLink]="['/itemDescription', item._id]"
                      >View</button>

                    <button class="btn btn-danger m-l-10"
                          (click)="onDeleteItem(item)">Remove</button> </td>
              </ng-container>

              <!-- Footer -loading & nodata-->
              <ng-container matColumnDef="loading">
                  <td mat-footer-cell *matFooterCellDef colspan="6"> Loading data... </td>
              </ng-container>

              <ng-container matColumnDef="noData">
                  <td mat-footer-cell *matFooterCellDef colspan="6"> No data. </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

              <tr mat-footer-row *matFooterRowDef="['loading']" [ngClass]="{'hide':dataSource!==null}"
                  class="footer-row"></tr>

              <tr mat-footer-row *matFooterRowDef="['noData']"
                  [ngClass]="{'hide':!(dataSource ==null || dataSource.data.length ===0)}" class="footer-row"></tr>
          </table>
          <mat-paginator #paginator [pageSizeOptions]='[5]' showFirstLastButtons></mat-paginator>
        </div>
  </div>
</div>


