import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Item } from 'src/app/models/item-model';
import { InventoryService } from 'src/app/services/inventory.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { CategoryService } from '../../services/category.service';
import { Category } from '../../models/category-model';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Employee } from 'src/app/models/employee-model';
import { EmployeeService } from 'src/app/services/employee.service';
import Swal from 'sweetalert2';



@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.css'],
})
export class AddItemComponent implements OnInit {
  isLoading: boolean = false;
  uploadFiles: any = [];
  filesUp:any;
  itemData: Item = {
    category: null,
    brand: null,
    label: null,
    description: null,
    price: null,
    uploads: null,
    allocatedPerson: null,
    status: 'AVAILABLE',
    purchaseDate: null,
  };
  maxDate: any;

  items: Item[] = [];
  categoryList: Category[] = [];
  allCategories: Observable<Category[]>;
  employeeList: Employee[] = [];
  allEmployees: Observable<Employee[]>;

  addItemForm: FormGroup;

  categoryControl = new FormControl();
  employeeControl = new FormControl();

  constructor(
    private inventoryService: InventoryService,
    private categoryService: CategoryService,
    private employeeService: EmployeeService
  ) {}

  ngOnInit(): void {

    this.addItemForm = new FormGroup({
      category: new FormControl(null, Validators.required),
      brand: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      label: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      description: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
      price: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      uploads: new FormControl(null, Validators.max(5)),
      allocatedPerson: new FormControl(null),
      date: new FormControl(null, Validators.required)
    });

    this.categoryService.getAllCategories().subscribe((categories) => {
      this.categoryList = categories.data;
      this.allCategories = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterCategory(value))
      );
    });

    this.employeeService.getAllEmployees().subscribe((employees) => {
      this.employeeList = employees.data;
      this.allEmployees = this.employeeControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterEmployee(value))
      );
    });

    this.futureDateDisable();
    // this.uploadLimit(document.querySelector('#upload'));
    // this.uploadLimit();
  }

  private filterCategory(catType: string): Category[] {
    let filterValue = ""
    catType ?  filterValue = catType.toLowerCase() : ''
    return this.categoryList.filter((cat) =>
      cat.categoryType.toLowerCase().includes(filterValue)
    );
  }

  private filterEmployee(employeeName: string): Employee[] {
    const filterValue = employeeName.toLowerCase();
    return this.employeeList.filter((employee) =>
      employee.employeeName.toLowerCase().includes(filterValue)
    );
  }

  selectedCategory(category): void {
    this.itemData.category = category;
  }

  selectedEmployee(employee): void {
    this.itemData.allocatedPerson = employee;
  }



  onFileUpload(file) {
    for (let i = 0; i < file.length; i++) {
      if(file[i].type === "video/mp4") {
        this.deleteAllUploadFiles();
        // this.addItemForm.reset();
        return SweetalertService.error('Videos are not allowed to upload!');
      } else {
        this.uploadFiles.push(file[i]);

        if(this.uploadFiles.length > 5) {
          // this.addItemForm.reset();
          this.deleteAllUploadFiles();
          return SweetalertService.error('Only Allowed 5 Uploads');
        }
      }
    }

    // for (let i = 0; i < file.length; i++) {
    //   this.uploadFiles.push(file[i]);
    // }
  }

  // uploadLimit() {
  //   // return input.addEventListener('change', (event) => {
  //   //   this.filesUp = input.files;
  //   //   if (this.filesUp.length > 5) {
  //   //     this.addItemForm.reset();
  //   //     this.deleteAllUploadFiles();
  //   //     return SweetalertService.error('Only Allowed 5 Uploads');
  //   //   }
  //   // });

  //   // if(this.uploadFiles.length > 5) {
  //   //   return SweetalertService.error('Only Allowed 5 Uploads');
  //   // }
  // }



  onAddItem() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to add new item ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Add it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.addItemFunction();
        // Swal.fire('Added!', 'Your file has been Added.', 'success');
        this.itemData.category = ""
        // Swal.fire('Added!', 'Your file has been Added.', 'success').then(() => {
        //     location.href = `/addItem`
        //   });
      }
    });
  }

  addItemFunction() {
    this.isLoading = true;

    if (this.itemData.allocatedPerson) {
      this.itemData.status = 'ALLOCATED';
    }
    // console.log(this.itemData)
    this.inventoryService.addItem(this.itemData).subscribe((res) => {
      if (res.success) {
        if (this.uploadFiles === null) {
          this.isLoading = false;
          // this.categoryControl.reset();
          // this.employeeControl.reset();
          SweetalertService.success('Item Added');
          this.addItemForm.reset();
        } else {
          this.inventoryService
            .uploadFile(this.uploadFiles, res.data)
            .subscribe((uploadRes) => {
              if (uploadRes.success === true) {
                this.isLoading = false;
                // this.categoryControl.reset();
                // this.employeeControl.reset();
                // SweetalertService.success('Item Added');
                this.addItemForm.reset();
                this.uploadFiles = [];
              } else if (uploadRes.success === false) {
                this.isLoading = false;
                SweetalertService.error(
                  'Item record added successfully! But file upload failed. Please upload file again in edit mode. ' +
                    uploadRes.error
                );
              }
            });
        }
      } else {
        this.isLoading = false;
        SweetalertService.error(res.error);
      }
    });
  }

  onCancelItem() {
    this.addItemForm.reset();
  }

  futureDateDisable() {
    let dtToday = new Date();

    let month: any = dtToday.getMonth() + 1;
    let day: any = dtToday.getDate();
    let year: any = dtToday.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    this.maxDate = year + '-' + month + '-' + day;
  }

  deleteAllUploadFiles() {
    this.uploadFiles = [];
  }

  deleteTempFile(file) {
    this.uploadFiles.splice(file, 1);
  }
}
