<div id="page-wrapper">
  <div class="container-fluid">
    <!-- ============================================================== -->
    <!-- wallet, & manage users widgets -->
    <!-- ============================================================== -->
    <!-- .row -->
    <div>
      <ol class="breadcrumb">
        <li><a>Available products</a></li>
      </ol>
    </div>

    <div class="row m-l-5">
      <div class="col-md-12">
        <button
          routerLink="/addItem"
          target=""
          class="
            btn btn-lg btn-primary
            pull-right
            m-l-20
            hidden-xs hidden-sm
            waves-effect waves-light
            m-r-20
          "
        >
          Add Item
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div
        class="col-lg-3 col-md-3 col-sm-4 col-xs-12"
        *ngFor="let item of sortedFinalItemData"
      >
        <a [routerLink]="['/availableProductDiscription', item._id]">
          <div class="panel panel-default">
            <div class="panel-wrapper collapse in">
              <div class="panel-body">
                <div class="first-Letter-parent">
                  <div
                    class="first-Letter-container"
                    [ngStyle]="{ backgroundColor: item.colorCode,
                              width: '50px',
                              height: '50px',
                              textAlign: 'center',
                              borderRadius: '50%' }">
                    <div class="first-Letter">{{ item.firstLetter }}</div>
                  </div>
                </div>
                <div class="text-center categoryName">{{ item.categoryName | uppercase}}</div>
                <div class="text-center categoryCount">{{ item.categoryCount }}<span style="margin-left: 8px; font-size: 15px;">available</span></div>
                <div class="m-t-15 collapseblebox dn">
                  <div class="well"></div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
