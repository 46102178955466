import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Category } from 'src/app/models/category-model';
import { Employee } from 'src/app/models/employee-model';
import { Item } from 'src/app/models/item-model';
import { CategoryService } from 'src/app/services/category.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { InventoryService } from 'src/app/services/inventory.service';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { Util } from 'src/app/common/utill';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-item',
  templateUrl: './edit-item.component.html',
  styleUrls: ['./edit-item.component.css'],
})
export class EditItemComponent implements OnInit {
  isLoading: boolean = false;
  id: any;
  employeeName: string;
  category: string;
  categoryId: any;
  uploadFiles: any = [];

  //uploaded files in db
  fileList: any;
  filePath: string = Util.apiPublicFile();

  itemData: any = {
    category: null,
    brand: null,
    label: null,
    description: null,
    price: null,
    allocatedPerson: null,
    purchaseDate: null,
  };

  maxDate: any;

  items: Item[] = [];
  categoryList: Category[] = [];
  allCategories: Observable<Category[]>;
  employeeList: Employee[] = [];
  allEmployees: Observable<Employee[]>;

  editItemForm: FormGroup;
  categoryControl = new FormControl();
  employeeControl = new FormControl();

  constructor(
    private inventoryService: InventoryService,
    private categoryService: CategoryService,
    private employeeService: EmployeeService,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((paramMap: ParamMap) => {
      if (paramMap.has('id')) {
        this.id = paramMap.get('id');

        this.inventoryService.refreshNeeded.subscribe(() => {
          this.getIndividualItemDiscription(this.id);
        });
        this.getIndividualItemDiscription(this.id);
      } else {
        return SweetalertService.error('Can not find the item');
      }
    });

    this.editItemForm = new FormGroup({
      category: new FormControl(null, Validators.required),
      brand: new FormControl(null, [Validators.required, Validators.maxLength(20)]),
      label: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      description: new FormControl(null, [Validators.required, Validators.maxLength(150)]),
      price: new FormControl(null, [Validators.required, Validators.maxLength(10)]),
      uploads: new FormControl(null),
      allocatedPerson: new FormControl(null),
      date: new FormControl(null, Validators.required),
    });

    this.categoryService.getAllCategories().subscribe((categories) => {
      this.categoryList = categories.data;

      this.allCategories = this.categoryControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterCategory(value))
      );
    });

    this.employeeService.getAllEmployees().subscribe((employees) => {
      this.employeeList = employees.data;
      this.allEmployees = this.employeeControl.valueChanges.pipe(
        startWith(''),
        map((value) => this.filterEmployee(value))
      );
    });

    this.futureDateDisable();
    // this.uploadLimit(document.querySelector('#upload'));
  }

  getIndividualItemDiscription(id: any) {
    this.inventoryService.getIndividualItemDiscription(id).subscribe((res) => {
      this.fileList = res.data.uploads;
      // console.log(this.fileList);

      if (res.data.mainData.allocatedPerson) {
        this.employeeName = res.data.mainData.allocatedPerson.employeeName;
      } else {
        this.employeeName = 'Not Allocated';
      }

      const date = res.data.mainData.purchaseDate.slice(0, 10);
      // console.log(date)

      this.itemData = {
        category: res.data.mainData.category.categoryType,
        brand: res.data.mainData.brand,
        label: res.data.mainData.label,
        description: res.data.mainData.description,
        price: res.data.mainData.price,
        allocatedPerson: this.employeeName,
        purchaseDate: date,
      };
      this.category = res.data.mainData.category.categoryType;
      this.categoryId = res.data.mainData.category._id;
    });
  }

  private filterCategory(catType: string): Category[] {
    const filterValue = catType.toLowerCase();
    return this.categoryList.filter((cat) =>
      cat.categoryType.toLowerCase().includes(filterValue)
    );
  }

  private filterEmployee(employeeName: string): Employee[] {
    const filterValue = employeeName.toLowerCase();
    return this.employeeList.filter((employee) =>
      employee.employeeName.toLowerCase().includes(filterValue)
    );
  }

  selectedCategory(category): void {
    this.itemData.category = category;
  }

  selectedEmployee(employee): void {
    this.itemData.allocatedPerson = employee;
  }

  // uploadLimit(input: any) {
  //   return input.addEventListener('change', (event) => {
  //     const files = input.files;
  //     if (files.length > 5) {
  //       this.editItemForm.reset();
  //       this.deleteAllUploadFiles();
  //       return SweetalertService.error('Only Allowed 5 Uploads');
  //     }
  //   });
  // }

  onFileUpload(file) {
    for (let i = 0; i < file.length; i++) {
      if(file[i].type === "video/mp4") {
        this.deleteAllUploadFiles();
        return SweetalertService.error('Videos are not allowed to upload!');
      } else {
        this.uploadFiles.push(file[i]);
        if(this.uploadFiles.length > 5) {
          this.deleteAllUploadFiles();
          return SweetalertService.error('Only Allowed 5 Uploads');
        }
      }
    }

  }


  onEditItem() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Are you sure you want to save changes ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Save changes!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.editItemFunction();
        Swal.fire('Added!', 'Your file has been changed.', 'success');
      }
    });
  }

  editItemFunction() {
    this.isLoading = true;

    if (
      this.itemData.allocatedPerson === 'Not Allocated' ||
      this.itemData.allocatedPerson === ''
    ) {
      this.itemData.allocatedPerson = null;
      this.itemData.status = 'AVAILABLE';
    }

    // console.log(this.itemData)

    this.inventoryService
      .updateItem(this.itemData, this.id)
      .subscribe((res) => {
        if (res.success) {
          if (!this.uploadFiles) {
            this.isLoading = false;
            // SweetalertService.success('Item Updated');
            this.router.navigate(['']);
          } else {
            this.inventoryService
              .uploadFile(this.uploadFiles, res.data)
              .subscribe((uploadRes) => {
                if (uploadRes.success == true) {
                  this.isLoading = false;
                  // SweetalertService.success('Item Updated');
                  this.router.navigate(['']);
                } else if (uploadRes.success == false) {
                  this.isLoading = false;
                  SweetalertService.error(
                    'Item record added successfully! But file upload failed' +
                      uploadRes.error
                  );
                }
              });
          }
        } else {
          this.isLoading = false;
          SweetalertService.error(res.error);
        }
      });
    this.itemData = null;
  }

  viewFile(fileName: any) {
    window.open(this.filePath + fileName, '_blank');
  }

  futureDateDisable() {
    let dtToday = new Date();

    let month: any = dtToday.getMonth() + 1;
    let day: any = dtToday.getDate();
    let year: any = dtToday.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();
    this.maxDate = year + '-' + month + '-' + day;
  }

  onDeleteUpload(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
        this.deleteFunction(id);
      }
    });
  }

  deleteFunction(id: string) {
    this.inventoryService.deleteUploadItem(id).subscribe((res) => {
      // console.log(res);
      if (res.success) {
        SweetalertService.success(res.message);
      } else {
        SweetalertService.error(res.error);
      }
    });
  }

  deleteAllUploadFiles() {
    this.uploadFiles = [];
  }

  deleteTempFile(file) {
    console.log(file);
    this.uploadFiles.splice(file, 1);
  }
}
