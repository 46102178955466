import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/user-model';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.css'],
})
export class MainLayoutComponent implements OnInit {
  currentUser: User = {
    userName: null,
    password: null,
    email: null,
  };
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getUser().subscribe((res) => {
      this.currentUser = res;
    });
  }

  logout() {
    this.authService.logoutUser();
  }
}
