import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, tap } from 'rxjs/operators';
import { Util } from '../common/utill';
import { AppResponse } from '../models/response';

import { User } from '../models/user-model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  registerUser(userData: User): Observable<AppResponse<string>> {
    const url = Util.apiPublicUrl('create');

    return this.http.post<AppResponse<string>>(url, userData).pipe(
      map((res) => {
        return res;
      })
    );
  }
  loginUser(userData: User): Observable<AppResponse<string>> {
    const url = Util.apiPublicUrl('login');
    return this.http.post<AppResponse<string>>(url, userData).pipe(map(res => {
      if (res.success){
        this.setToken('token', res.data);
      }
      return res;
    }));
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }
  logoutUser() {
    localStorage.removeItem('token');
    this.router.navigate(['/']);
  }

  setToken(tokenName: string, tokenValue: string) {
    localStorage.setItem(tokenName, tokenValue);
  }
  static getToken() {
    return localStorage.getItem('token');
  }

  getUser() {
    const url = Util.apiAuthUrl('get-user');
    return this.http.get<User>(url).pipe(
      map(
        (res) => {
          return res;
        },
        (err) => {
          return err;
        }
      )
    );
  }
}
