import { Injectable } from '@angular/core';
import { AppResponse } from '../models/response';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Category } from '../models/category-model';
import { Observable, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Util } from '../common/utill';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  
  private _refreshNeeded = new Subject<void>();

  constructor(private http: HttpClient) {}

  get refreshNeeded() {
    return this._refreshNeeded;
  }

  addCategory(category: Category): Observable<AppResponse<string>> {
    const url = Util.apiAuthUrl('category');

    return this.http.post<AppResponse<string>>(url, category).pipe(
      map(
        (res) => {
          return res;
        },
        (err) => {
          return err;
        }
      ),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  getAllCategories(): Observable<AppResponse<Category[]>> {
    const url = Util.apiAuthUrl('category/view');
    return this.http.get<AppResponse<Category[]>>(url).pipe(
      map(
        (res) => {
          return res;
        },
        (err) => {
          return err;
        }
      )
    );
  }

  deleteCategory(category: Category): Observable<Category> {
    const url = Util.apiAuthUrl(`deleteCategory/${category._id}`);

    return this.http.delete<Category>(url).pipe(
      map(
        (res) => {
          return res;
        },
        (err) => {
          return err;
        }
      ),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }

  updateCategory(
    category: Category,
    updateCategoryData: Category
  ): Observable<AppResponse<string>> {
    const url = Util.apiAuthUrl(`updateCategory/${category._id}`);

    return this.http.put<AppResponse<string>>(url, updateCategoryData).pipe(
      map((res) => {
        return res;
      }),
      tap(() => {
        this._refreshNeeded.next();
      })
    );
  }
}
