import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ɵɵqueryRefresh,
} from '@angular/core';
import Swal from 'sweetalert2';
import { CategoryService } from 'src/app/services/category.service';
import { Category } from 'src/app/models/category-model';
import { SweetalertService } from 'src/app/services/sweetalert.service';
import { Subject } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css'],
})
export class CategoryComponent implements OnInit {
  categoryData: Category = {
    categoryType: null,
  };
  categories: Category[] = [];
  sortedCategories: Category[] = [];

  updateCategoryData: Category;

  searchKey: string;
  displayedColumns: string[] = ['category', 'action'];
  dataSource: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private categoryService: CategoryService) {}

  ngOnInit(): void {
    
    this.categoryService.refreshNeeded.subscribe(() => {
      this.getAllCategories();
    });
    this.getAllCategories();
  }

  getAllCategories() {
    this.categoryService.getAllCategories().subscribe(
      (res) => {
        this.categories = res.data;
        this.sortedCategories = this.categories.sort((a, b) => {
          if (a.categoryType < b.categoryType) {
            return -1;
          }
          if (a.categoryType > b.categoryType) {
            return 1;
          }
          return 0;
        });
        this.dataSource = new MatTableDataSource(this.sortedCategories);
        // console.log(this.dataSource)
        this.dataSource.paginator = this.paginator;
      },
      (err) => SweetalertService.error('Server error!')
    );
  }

  addCategory() {
    this.categoryService.addCategory(this.categoryData).subscribe(
      (res) => {
        if (res.success === true) {
          SweetalertService.success('Category Added');
        } else {
          SweetalertService.error(res.error);
        }
      },
      (err) => {
        SweetalertService.error(err.message);
      }
    );
    this.categoryData.categoryType = '';
  }

  deleteCategory(category: Category) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this! When category removed, relavant items also will be removed",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteFunction(category);
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  }

  deleteFunction(category: Category) {
    this.categoryService.deleteCategory(category).subscribe(
      (res) => {
        // SweetalertService.success('Category Deleted');
      },
      (err) => {
        SweetalertService.error(err.message);
      }
    );
  }

  updateCategory() {
    this.categoryService
      .updateCategory(this.updateCategoryData, this.categoryData)
      .subscribe(
        (res) => {
          if (res.success === true) {
            SweetalertService.success('Category Updated');
          } else {
            SweetalertService.error(res.error);
          }
        }
        // ,
        // (err) => {
        //   SweetalertService.error(err.message);
        // }
      );
    // console.log(this.updateCategoryData);
    this.updateCategoryData = null;
    this.categoryData.categoryType = '';
  }

  setUpdateCategoryData(category: Category) {
    this.updateCategoryData = category;
    this.categoryData.categoryType = category.categoryType;
  }

  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
  onAddCategoryClick() {
    this.categoryData.categoryType = '';
  }
}
